import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import { SocobiomaButton } from '../../socobioma_components'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { ACCESS_TOKEN } from '../../../constants'
import { usePromiseTracker, trackPromise } from 'react-promise-tracker'
import LoadingSpinner from '../../generalComponents/loadingSpinner/LoadingSpinner.js'
import { Link, Redirect } from 'react-router-dom'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import VerInstanciasRealizadas from './verInstanciasRealizadas'
import { IconButton } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import autoLogout from '../../../generalFunctions/autoSignout'


const useStyles = makeStyles({
  card: {
    marginTop: '20px',
    width: '100%',
    maxWidth: 750,
    backgroundColor: 'rgba(255, 255, 255, 0.95)'
  },
  title: {
    color: '#3C810D'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  error: {
    color: '#F0072D'
  },
  titleRecomendation: {
    color: '#FF7043'
  },
  addIcon: {
    color: '#77BF3F'
  }
})

export default function ScreenVerTratamiento (props) {
  const classes = useStyles()
  const apiURL = process.env.REACT_APP_API_URL
  const config = { headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) } }
  const idTratamiento = props.match.params.idTratamiento
  const { promiseInProgress } = usePromiseTracker()
  const [tratamiento, setTratamiento] = React.useState()
  const [dataRedirect, setdataRedirect] = React.useState()
  const [redirect, setRedirect] = React.useState(false)
  const [tratamientoDeberiaterminar, setTratamientoDeberiaterminar] = React.useState(false)
  const [widthTable, setWidthTable] = React.useState(0)

  const postFinalizar = function (event) {
    var url = `${apiURL}/ficha_tratamiento/${idTratamiento}/finalizar`
    trackPromise(
      axios.post(url, {}, config).then(response => {
        setTratamiento({ ...tratamiento, activo: false })
      }).catch(responseError => {
        if (responseError.response.status === 401) {
          autoLogout()
        }
        console.log('Error al finalizar: \n', responseError)
      })
    )
  }

  const finalizar = function () {
    var result = window.confirm('¿Desea finalizar el tratamiento?')
    if (result) {
      postFinalizar()
    }
  }
  const modificar = function () {
    setdataRedirect({ path: '/modificar_ficha_tratamiento/' + idTratamiento })
    setRedirect(true)
  }

  const realizar = function () {
    setdataRedirect({ path: '/realizar_tratamiento/' + idTratamiento })
    setRedirect(true)
  }

  React.useEffect((event) => {
    const url = `${apiURL}/ficha_tratamiento/${idTratamiento}`
    trackPromise(
      axios.get(url, config).then((response) => {
        setTratamiento(response.data)

      }).catch(_error => {
        if (_error.response.status === 401) {
          autoLogout()
        }
        console.log('Error al obtener el tratamiento: \n', _error)
      }))
  }, [])

  function convertDate (fecha) {
    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    const currentDate = iOS ? new Date(fecha.substr(0, 19)) : new Date(fecha)
    const formattedDate = (currentDate.getDate()).toString() + '/' + (currentDate.getMonth()).toString() + '/' + currentDate.getFullYear().toString()
    return formattedDate
  }

  function getHour (fecha) {
    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    const date = iOS ? new Date(fecha.substr(0, 19)) : new Date(fecha)
    const hours = date.getHours()
    const minutes = '0' + date.getMinutes()
    const formattedTime = hours + ':' + minutes.substr(-2)
    return formattedTime
  }

  function addLine (texto) {
    return (texto) ? texto.replace(/_/g, '/') : null
  }

  function addDays (datemod, days) {
    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    var date = iOS ? new Date(datemod.valueOf().substr(0, 19)) : new Date(datemod.valueOf())
    date.setDate(date.getDate() + days)
    return date
  }

  React.useEffect((event) => {
    if (tratamiento && tratamiento.activo && tratamiento.fechaHoraComienzo && tratamiento.duracion) {
      var date = tratamiento.fechaHoraComienzo
      var moddate = addDays(date, tratamiento.duracion)
      if (moddate < Date.now()) {
        setTratamientoDeberiaterminar(true)
      }
    }
  }, [tratamiento])

  React.useEffect(() => {
    function updateSize () {
      setWidthTable(window.innerWidth - 100)
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        {redirect
          ? (
            <Redirect
              push={dataRedirect.push}
              to={{
                pathname: dataRedirect.path
              }}
            />
          )
          : (promiseInProgress
            ? (
              <CardContent>
                <LoadingSpinner />
              </CardContent>
            )
            : ((tratamiento)
              ? (
                <CardContent>
                  <Typography align='center' className={classes.title} variant='h4'>
                    Tratamiento #{idTratamiento}
                  </Typography>
                  {tratamiento.activo
                    ? (
                      <Typography className={classes.title} align='center' variant='subtitle2'>
                        Este tratamiento está activo
                      </Typography>
                    )
                    : (
                      <Typography className={classes.error} align='center' variant='subtitle2'>
                        Este tratamiento está finalizado
                      </Typography>)}
                  {tratamientoDeberiaterminar &&
                    <Typography className={classes.titleRecomendation} align='center' variant='subtitle2'>
                      Duración estipulada excedida
                    </Typography>}
                  <Typography align='right' variant='subtitle2'>
                    <Link to={'/fichas/' + tratamiento.idFichaAnimal}>
                      Ficha de Ingreso #{tratamiento.idFichaAnimal}
                    </Link>
                  </Typography>
                  <Table className={classes.table}>
                    <TableBody>
                      <TableRow>
                        <TableCell align='left'> Fecha de comienzo: {convertDate(tratamiento.fechaHoraComienzo)}</TableCell>
                        <TableCell align='left'> Hora: {getHour(tratamiento.fechaHoraComienzo)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align='left'> Droga/Procedimiento:</TableCell>
                        <TableCell align='left'> {tratamiento.tipoTratamiento}</TableCell>
                      </TableRow>
                      {tratamiento.via &&
                        <TableRow>
                          <TableCell align='left'> Vía:</TableCell>
                          <TableCell align='left'>{addLine(tratamiento.via)} </TableCell>
                        </TableRow>}
                      {tratamiento.medicamento &&
                        <TableRow>
                          <TableCell align='left'> Medicamento:</TableCell>
                          <TableCell align='left'> {tratamiento.medicamento} </TableCell>
                        </TableRow>}
                      {tratamiento.cantidadDosis &&
                        <TableRow>
                          <TableCell align='left'> Dosis:</TableCell>
                          <TableCell align='left'>{tratamiento.cantidadDosis} {addLine(tratamiento.dosis)}</TableCell>
                        </TableRow>}
                      {tratamiento.cantidadDar &&
                        <TableRow>
                          <TableCell align='left'> Dar:</TableCell>
                          <TableCell align='left'> {tratamiento.cantidadDar} {addLine(tratamiento.dar)} </TableCell>
                        </TableRow>}
                      <TableRow>
                        <TableCell align='left'> Frecuencia:</TableCell>
                        <TableCell align='left'>{tratamiento.frecuencia} hs </TableCell>
                      </TableRow>
                      {tratamiento.duracion &&
                        <TableRow>
                          <TableCell align='left'> Duración:</TableCell>
                          <TableCell align='left'>{tratamiento.duracion} días</TableCell>
                        </TableRow>}
                      {tratamiento.instruccionesEspeciales &&
                        <TableRow>
                          <TableCell align='left'> Instrucciones Especiales:</TableCell>
                          <TableCell align='left'>{tratamiento.instruccionesEspeciales}</TableCell>
                        </TableRow>}
                    </TableBody>
                  </Table>
                  {// --------------- TABLA INSTANCIAS REALIZADAS ------------  
                  }

                  <Typography style={{ marginTop: '25px', color: '#3C810D' }} align='center'>
                    <i>   Instancias Realizadas </i>
                  </Typography>
                  <div align='right'>
                    <IconButton aria-label='delete' onClick={realizar} >
                      <AddCircleIcon className={classes.addIcon} fontSize='large' />
                    </IconButton>

                  </div>
                  <List>
                    <ListItem>
                      <VerInstanciasRealizadas width={widthTable} instancias={tratamiento.instancias} />
                    </ListItem>
                  </List>
                  {tratamiento.activo &&
                    <List>
                      <ListItem>
                        <SocobiomaButton
                          label='Modificar tratamiento' onClick={modificar}
                        />
                        <SocobiomaButton
                          label='Finalizar tratamiento' onClick={finalizar}
                        />
                      </ListItem>
                    </List>}
                </CardContent>
              )
              : (
                <CardContent className={classes.card}>
                  <Typography align='center' className={classes.error} variant='h5'>
                    El tratamiento que usted solicita no se encuentra en el sistema.
                  </Typography>
                </CardContent>
              )))}
      </Card>
    </div>
  )
}
