import React from 'react'
import axios from 'axios'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import 'react-responsive-ui/style.css'
import MuiPhoneNumber from 'material-ui-phone-number'
import { ACCESS_TOKEN } from '../../../constants'
import { usePromiseTracker, trackPromise } from 'react-promise-tracker'
import ErrorMsg from '../../generalComponents/Message/ErrorMessage'
import SuccessMessage from '../../generalComponents/Message/SuccessMessage'
import { SocobiomaTextField, SocobiomaSelect, SocobiomaButton } from '../../socobioma_components'
import LoadingSpinner from '../../generalComponents/loadingSpinner/LoadingSpinner.js'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import autoSignout from '../../../generalFunctions/autoSignout'

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
    height: 'auto',
    width: '100%'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  formControl: {
    backgroundColor: '#FCFAFA',
    margin: theme.spacing(1),
    width: '100%'
  },
  card: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
    width: '100%',
    maxWidth: 750,
    backgroundColor: 'rgba(255, 255, 255, 0.95)'
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto'
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2)
  },
  textField: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '20px',
    marginBottom: 'auto',
    height: 'auto',
    width: '100%'
  },
  title: {
    color: '#3C810D'
  }
}))

export default function ScreenAltaVoluntario (props) {
  const apiURL = process.env.REACT_APP_API_URL
  const classes = useStyles()
  const [departamentos, setDepartamentos] = React.useState([])
  const [deptoError, setDeptoError] = React.useState(false)
  const [message, setMessage] = React.useState([])
  const [openError, setOpenError] = React.useState(false)
  const [contactoError, setContactoError] = React.useState(false)
  const [emailError, setEmailError] = React.useState(false)
  const [usuarioError, setUsuarioError] = React.useState(false)
  const [openSuccess, setOpenSuccess] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [showPassword, setShowPassword] = React.useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false)
  const [confirmPassword, setConfirmPassword] = React.useState('')
  const [passError, setPassError] = React.useState(false)
  const [report, setReport] = React.useState({
    nombre: '',
    apellido: '',
    email: '',
    contacto: '',
    departamento: '',
    username: '',
    password: '',
    rol: 'VOL'
  })

  const [nombreVacio, setNombreVacio] = React.useState(false)
  const [apellidoVacio, setApellidoVacio] = React.useState(false)

  const handleChange = attribute => event => {
    setReport({ ...report, [attribute]: event.target.value })
  }

  const handleChangeUserName = (event) => {
    setReport({ ...report, username: event.target.value.trim() })
  }

  function handleChangePhone (value) {
    if (value.length > 13) {
      value = value.slice(0, 13)
    }
    setReport({ ...report, contacto: value })
  }

  function limpiarFormulario () {
    setReport({
      ...report,
      nombre: '',
      apellido: '',
      email: '',
      contacto: '',
      departamento: '',
      username: '',
      password: '',
      rol: 'VOL'
    })
    setOpenError(false)
    setEmailError(false)
    setUsuarioError(false)
    setError(false)
    setPassError(false)
    setContactoError(false)
    setNombreVacio(false)
    setApellidoVacio(false)
    setDeptoError(false)
  }

  function constructUserName () {
    var string1 = report.nombre
    var string2 = report.apellido
    var espacio = / /g
    var punto = '.'
    var res = ''
    if (string2 === '') {
      res = string1.trim()
    } else {
      string1 = string1.trim()
      res = string1.concat(punto)
    }
    string2 = string2.trim()
    res = res.concat(string2).replace(/\s\s+/g, ' ')
    res = res.replace(espacio, punto)
    return res
  }

  function handleChangeEmail (event) {
    setReport({ ...report, email: event.target.value.trim() })
  }

  React.useEffect((event) => {
    setError(false)
    setOpenError(false)
    setOpenSuccess(false)
    setEmailError(false)
    setUsuarioError(false)
    setPassError(false)
    setContactoError(false)
    setNombreVacio(false)
    setApellidoVacio(false)
    setDeptoError(false)
  }, [report])

  React.useEffect((event) => {
    const url = `${apiURL}/departamentos`
    axios.get(url).then((response) => {
      setDepartamentos(response.data.map((obj) => {
        return obj
      }))
    }).catch(_error => {
      console.log('Error al ingresar el reporte:', _error)
      if (_error.response.status === 401) {
        autoSignout()

      }
    })
  }, [])

  const postAltaVoluntario = function () {
    var config = { headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) } }
    var abrioError = false
    if (report.nombre === '') {
      if (!abrioError) {
        setMessage('Debe especificar un nombre')
        setOpenError(true)
        abrioError = true
      }
      setNombreVacio(true)
    }

    if (report.apellido === '') {
      if (!abrioError) {
        setMessage('Debe especificar un apellido')
        setOpenError(true)
        abrioError = true
      }
      setApellidoVacio(true)
    }

    if ((report.email === '' || !(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(report.email)))) {
      if (!abrioError) {
        setMessage('El email ingresado no tiene el formato correcto')
        setOpenError(true)
        abrioError = true
      }
      setEmailError(true)
    }

    if (report.contacto.length < 12) {
      if (!abrioError) {
        setMessage('Debe especificar un número de contacto')
        setOpenError(true)
        abrioError = true
      }
      setContactoError(true)
    }

    if (report.departamento === '') {
      if (!abrioError) {
        setMessage('Debe seleccionar un departamento')
        setOpenError(true)
        abrioError = true
      }
      setDeptoError(true)
    }

    if (report.username === '') {
      if (!abrioError) {
        setMessage('Debe especificar un nombre de usuario')
        setOpenError(true)
        abrioError = true
      }
      setUsuarioError(true)
    }

    if (report.password.length < 6 || report.password.length > 20) {
      if (!abrioError) {
        setMessage('El password debe ser de 6 a 20 caracteres')
        setOpenError(true)
        abrioError = true
      }
      setPassError(true)
    }

    if ((report.password !== confirmPassword)) {
      if (!abrioError) {
        setMessage('Los password no coinciden')
        setOpenError(true)
        abrioError = true
      }
      setPassError(true)
    }

    if ((report.password.length < 6 || report.password.length > 20) || nombreVacio || apellidoVacio || report.username === '' ||
      report.contacto === '' || report.departamento === '' || (report.password !== confirmPassword) ||
      (report.contacto.length < 12) || (report.email === '' || !(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(report.email)))) {
      setError(true)
      window.scrollTo(0, 0)
    } else {
      var url = `${apiURL}/auth/signup`
      trackPromise(
        axios.post(url, report, config).then(response => {
          limpiarFormulario()
          setOpenSuccess(true)
          setMessage('El voluntario se ha registrado satisfactoriamente')
        }).catch(responseError => {
          if (responseError.response.status === 401) {
            autoSignout()
          }
          setMessage(responseError.response.data.message)
          if (responseError.response.data.message === 'El correo electrónico ya existe para otro voluntario.') {
            setEmailError(true)
          }
          if (responseError.response.data.message === 'El nombre de usuario ya existe para otro voluntario.') {
            setUsuarioError(true)
          }
          setOpenError(true)
        })
      )
    }
  }

  const goBack = function () {
    window.history.go(-1)
  }

  const { promiseInProgress } = usePromiseTracker()

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        <ErrorMsg open={openError} handleOpen={() => { setOpenError(!openError) }} message={message} />
        <SuccessMessage open={openSuccess} handleOpen={() => { setOpenSuccess(!openSuccess) }} message={message} />
        {promiseInProgress
          ? (
            <CardContent>
              <LoadingSpinner />
            </CardContent>
          )
          : (
            <CardContent>
              <Typography align='center' className={classes.title} variant='h4'>
                  Alta Voluntario
              </Typography>
              <form className={classes.container} noValidate autoComplete='off'>
                <List className={classes.root}>
                  <ListItem>
                    <SocobiomaTextField
                      label='Nombre*'
                      placeholder='Ingrese su nombre '
                      value={report.nombre}
                      error={nombreVacio && error}
                      onChange={handleChange('nombre')}
                      maxLength={45}
                      onBlur={() => {
                        setReport({ ...report, nombre: report.nombre.trim().replace(/\s\s+/g, ' '), username: constructUserName() })
                      }}
                    />
                  </ListItem>
                  <ListItem>
                    <SocobiomaTextField
                      label='Apellido*'
                      maxLength={45}
                      placeholder='Ingrese su apellido'
                      value={report.apellido}
                      error={apellidoVacio && error}
                      onChange={handleChange('apellido')}
                      onBlur={() => { setReport({ ...report, apellido: report.apellido.trim().replace(/\s\s+/g, ' '), username: constructUserName() }) }}
                    />
                  </ListItem>
                  <ListItem>
                    <SocobiomaTextField
                      label='Correo*'
                      placeholder='ejemplo@socobioma.com'
                      value={report.email}
                      error={emailError && error}
                      onChange={handleChangeEmail}
                      maxLength={45}
                    />
                  </ListItem>
                  <ListItem>
                    <MuiPhoneNumber
                      name='phone'
                      label='Teléfono*'
                      data-cy='user-phone'
                      onlyCountries={['uy']}
                      defaultCountry='uy'
                      countryCodeEditable={false}
                      fullWidth='true'
                      value={report.contacto}
                      error={contactoError && error}
                      onChange={handleChangePhone}
                      className={classes.phoneNumber}
                    />
                  </ListItem>
                  <SocobiomaSelect
                    label='Departamento*'
                    value={report.departamento}
                    onChange={handleChange('departamento')}
                    error={deptoError && error}
                    items={departamentos.map((dep) => { return dep.idDepartamento })}
                    itemsTags={departamentos.map((dep) => { return dep.nombre })}
                  />
                  <ListItem>
                    <SocobiomaTextField
                      label='Nombre de usuario'
                      placeholder='Autogenenerado nombre.apellido'
                      value={report.username}
                      error={usuarioError && error}
                      onChange={handleChangeUserName}
                      maxLength={91}
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      id='outlined-password-input'
                      label='Password'
                      className={classes.textField}
                      autoComplete='current-password'
                      type={showPassword ? 'text' : 'Password'}
                      margin='normal'
                      variant='filled'
                      error={passError}
                      onChange={handleChange('password')}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={() => { setShowPassword(!showPassword) }}
                              onMouseDown={event => {
                                event.preventDefault()
                              }}
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      id='outlined-password-input'
                      label='Confirmar Password'
                      className={classes.textField}
                      autoComplete='current-password'
                      type={showConfirmPassword ? 'text' : 'Password'}
                      margin='normal'
                      variant='filled'
                      onChange={(event) => { setConfirmPassword(event.target.value) }}
                      error={passError}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={() => { setShowConfirmPassword(!showConfirmPassword) }}
                              onMouseDown={event => {
                                event.preventDefault()
                              }}
                            >
                              {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </ListItem>
                  <ListItem>
                    <SocobiomaButton
                      label='Cancelar'
                      onClick={goBack}
                    />
                    <SocobiomaButton
                      label='Registrar Voluntario'
                      onClick={postAltaVoluntario}
                    />
                  </ListItem>
                </List>
              </form>
            </CardContent>
          )}
      </Card>
    </div>
  )
}
