import React from 'react'
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { Link } from 'react-router-dom'
import Pagination from 'material-ui-flat-pagination'
import Grid from '@material-ui/core/Grid'
import { ACCESS_TOKEN } from '../../../constants'
import Button from '@material-ui/core/Button'
import FilterListIcon from '@material-ui/icons/FilterList'
import Drawer from '@material-ui/core/Drawer'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import { FiltersTextField, FiltersComboBox } from '../../generalComponents/FilterComponents/FiltersTextField.js'
import { usePromiseTracker, trackPromise } from 'react-promise-tracker'
import LoadingSpinner from '../../generalComponents/loadingSpinner/LoadingSpinner.js'
import Avatar from '@material-ui/core/Avatar'
import { SocobiomaButton } from '../../socobioma_components'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import autoLogout from '../../../generalFunctions/autoSignout'


const useStyles = makeStyles({
  card: {
    width: 280,
    height: 220,
    display: 'inline-block',
    margin: '5px 5px 5px 5px'
  },
  cardTitleSpinner: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
    width: '93%',
    maxWidth: 1439,
    backgroundColor: 'rgba(255, 255, 255, 0.8)'
  },
  hover: {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, .8)'
    }
  },
  warning: {
    width: '93%',
    marginLeft: 'auto',
    marginRight: 'auto',
    color: '#3C810D'
  },
  filter: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    position: 'fixed',
    top: '10',
    right: '0',
    '&:active': {
      backgroundColor: 'rgba(255, 255, 255, 1)'
    },
    '&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 1)'
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 1)',
      borderColor: '#3EA91D'
    }
  },
  root: {
    maxWidth: '261px',
    width: '100%',
    padding: '0px'
  },
  title: {
    color: '#3C810D',
    align: 'center'
  },
  bigAvatar: {
    width: 70,
    height: 70
  }
})

function CardUsuario ({ usuario }) {
  const classes = useStyles()
  const [fotoPerfil, setFotoPerfil] = React.useState()
  const apiURL = process.env.REACT_APP_API_URL

  async function cargarFotoPerfil () {
    const headers = {
      'Content-Type': 'text/plain',
      headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) }
    }
    var url = `${apiURL}${usuario.foto}`
    axios.get(url, headers).then((response) => {
      setFotoPerfil(response.data)
    }).catch((_error) => {
      if (_error.response.status === 401) {
        autoLogout()
      }
      console.log('Error al obtener los usuarios')
    })
  }

  React.useEffect((event) => {
    if (usuario && usuario.foto) {
      cargarFotoPerfil()
    }
  }, [usuario])

  function toUpLowerCase (texto) {
    let aux = texto.toLowerCase()
    aux = aux.charAt(0).toUpperCase() + aux.slice(1)
    return aux
  }

  return (
    <Link to={'/usuario/' + usuario.idUsuario} underline='none' className={classes.hover}>
      <Card className={classes.card}>
        <CardContent>
          <Table>
            <TableBody>
              {fotoPerfil
                ? (
                  <TableRow>
                    <TableCell align='left'>
                      <Avatar alt='' src={`data:image/jpeg;base64,${fotoPerfil}`} className={classes.bigAvatar} />
                    </TableCell>
                    <TableCell align='left'>
                      <Typography className={classes.title} variant='h5'>
                        {toUpLowerCase(usuario.nombre)} {toUpLowerCase(usuario.apellido)}
                      </Typography>
                    </TableCell>
                  </TableRow>)
                : (
                  <TableRow>
                    <Typography align='center' className={classes.title} variant='h5'>
                      {toUpLowerCase(usuario.nombre)} {toUpLowerCase(usuario.apellido)}
                    </Typography>
                  </TableRow>
                )}
            </TableBody>
          </Table>
          <Table>
            <TableBody>
              {usuario.departamento &&
                <TableRow>
                  <TableCell align='center'>
                    {usuario.departamento}
                  </TableCell>
                </TableRow>}
              {usuario.contacto &&
                <TableRow>
                  <TableCell align='center'>
                    {usuario.contacto}
                  </TableCell>
                </TableRow>}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </Link>
  )
}

export default function ScreenListarReportes () {
  const classes = useStyles()
  const apiURL = process.env.REACT_APP_API_URL
  const [usuarios, setUsuarios] = React.useState(null)
  const [offset, setOffset] = React.useState(0)
  const [open, setOpen] = React.useState(false)
  const [departamentos, setDepartamentos] = React.useState([])
  const [filters, setFilters] = React.useState({
    departamento: null,
    nombre: null,
    pag: 1,
    activo: null
  })

  const handleChange = attribute => event => {
    setFilters({ ...filters, [attribute]: event.target.value, pag: 1 })
    setOffset(0)
  }

  const obtenerUsuarios = function () {
    const url = `${apiURL}/usuarios/page`
    const config = {
      headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) },
      params: filters
    }
    trackPromise(
      axios.get(url, config).then((response) => {
        setUsuarios(response.data)
      }).catch((_error) => {
        if (_error.response.status === 401) {
          autoLogout()
        }
        console.log('Error al obtener los usuarios')
      })
    )
  }

  React.useEffect((event) => {
    obtenerUsuarios()
  }, [filters.pag])

  React.useEffect((event) => {
    const url = `${apiURL}/departamentos`
    trackPromise(
      axios.get(url).then((response) => {
        const deps = [null].concat(response.data.map((dep) => { return dep.nombre }))
        setDepartamentos(deps)
      }).catch((error) => {
        console.log(error)
      })
    )
  }, [])

  const { promiseInProgress } = usePromiseTracker()

  return (
    <div>
      <Button
        variant='outlined'
        className={classes.filter}
        onClick={() => { setOpen(true) }}
      >
        <FilterListIcon />
      </Button>
      <Drawer anchor='right' open={open} onClose={() => { setOpen(false) }}>
        <Typography style={{ marginTop: '10px' }} className={classes.title} align='center' variant='h5' gutterBottom>Filtros</Typography>
        <List className={classes.root}>
          <ListItem>
            <FiltersComboBox
              label='Departamentos'
              value={filters.departamento}
              onChange={handleChange('departamento')}
              items={[null].concat(departamentos)}
              itemsTags={['Todos'].concat(departamentos)}
            />
          </ListItem>
          <ListItem>
            <FiltersComboBox
              label='Activo/Inactivo'
              value={filters.activo}
              onChange={handleChange('activo')}
              items={[true, false, null]}
              itemsTags={['Activos', 'Inactivos', 'Todos']}
            />
          </ListItem>
          <ListItem>
            <FiltersTextField
              label='Nombre'
              value={filters.nombre}
              className={classes.textField}
              type='char'
              onChange={handleChange('nombre')}
            />
          </ListItem>
          <ListItem>
            <SocobiomaButton
              onClick={obtenerUsuarios}
              label='Buscar'
            />
          </ListItem>
        </List>
      </Drawer>
      <Card className={classes.cardTitleSpinner}>
        <CardContent>
          <Typography align='center' className={classes.title} variant='h4'>
            Usuarios
          </Typography>
        </CardContent>
      </Card>
      {
        promiseInProgress
          ? (
            <Card className={classes.cardTitleSpinner}>
              <CardContent>
                <LoadingSpinner />
              </CardContent>
            </Card>
          )
          : (usuarios && usuarios.content[0])
            ? (
              <Grid
                container
                spacing={0}
                alignItems='center'
                justify='center'
              >
                {usuarios && usuarios.content.map((usuario) => {
                  return (
                    <CardUsuario key={usuario.idUsuario} usuario={usuario} />)
                })}

                <div id='newLine' />
                <div class='fondoPag'>
                  {(usuarios) &&
                    <Pagination
                      limit={usuarios.size}
                      offset={offset}
                      total={usuarios.totalElements}
                      reduced='true'
                      onClick={(e, offset, page) => {
                        setOffset(offset)
                        setFilters({ ...filters, pag: page })
                      }}
                    />}
                </div>
              </Grid>
            ) : (
              <SnackbarContent
                variant='h5'
                className={classes.warning}
                message='No hay usuarios en el sistema con esas características'
              />)
      }
    </div>
  )
}
