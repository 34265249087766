// TODO contendor pagina Sumate.
// trae informacion del backend y llama a los componentes de presentacion pasandoles los datos pertinentes
import React from 'react'
import { render } from "react-dom";

import SuccessMessage from '../../generalComponents/Message/SuccessMessage'

import {  animateScroll as scroll } from "react-scroll";
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Pagination from 'material-ui-flat-pagination'
import Grid from '@material-ui/core/Grid'
import { ACCESS_TOKEN } from '../../../constants'
import { usePromiseTracker, trackPromise } from 'react-promise-tracker'
import LoadingSpinner from '../../generalComponents/loadingSpinner/LoadingSpinner.js'
import { ANIMAL_FILE_STATE } from '../../../constants/index'
import ErrorMsg from '../../generalComponents/Message/ErrorMessage'
import LinesEllipsis from 'react-lines-ellipsis'
import { Link } from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar'
import autoLogout from '../../../generalFunctions/autoSignout'


import Carousel from "../../generalComponents/Carousel/Carousel";
import "react-responsive-carousel/lib/styles/carousel.css";
import { Container, Row, Col } from "reactstrap";
import "./ScreenSumate.css"
const useStyles = makeStyles({
    card: {
        maxWidth: '900px',
        height: '96.5%',
        display: 'inline-block',
        marginTop: '3%',
        margin: '5px 5px 5px 5px',
        backgroundColor: 'rgba(255, 255, 255, 0.93)',

    },
    cardTitleSpinner: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 'auto',
        marginBottom: 'auto',
        width: '100%',
        maxWidth: 1448,
        backgroundColor: 'rgba(255, 255, 255, 0.8)'
    },
    table: {
        padding: '9px 3px 9px 3px',
        margin: '3px',
        height: '40%'

    },
    text: {
        wordBreak: 'break-word',
        maxWidth: '100px'
    },
    textLong: {
        wordBreak: 'break-word',
        maxWidth: '100px'
    },
    fecha: {
        whiteSpace: 'nowrap'
    },
    hover: {
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, .8)'
        }
    },
    filter: {
        backgroundColor: 'rgba(255, 255, 255, 1)',
        position: 'fixed',
        top: '10',
        right: '0',
        '&:active': {
            backgroundColor: 'rgba(255, 255, 255, 1)'
        },
        '&:focus': {
            backgroundColor: 'rgba(255, 255, 255, 1)'
        },
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 1)',
            borderColor: '#3EA91D'
        }
    },
    checkboxIcons: {
        color: '#20DD03'
    },
    checkboxIconsRed: {
        color: '#F53232'
    },
    checkboxText: {
        paddingLeft: '15px',
        color: '#757575'
    },
    checkboxContainer: {
        display: 'flex',
        alignContent: 'space-between'
    },
    root: {
        maxWidth: '261px',
        width: '100%',
        padding: '0px'
    },
    grid: {
        height: '100%',

    },
    title: {
        color: '#3C810D',
        
    },
    h6: {size: '1.132em',
    textAlign:'justify',
    fontFamily:'Source Sans Pro'},
    bigAvatar: {
        maxWidth: '900px',
        height: '91%',
        display: 'inline-block',
        marginTop: '3%',
        margin: '5px 5px 5px 5px',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        width: 158,
        height: 158
    }
})

export default function ScreenSumate() {
    const [message, setMessage] = React.useState([])
    const [openSuccess, setOpenSuccess] = React.useState(false)
    const classes = useStyles()


    function handleCloseMsj() {
        setOpenSuccess(false)
    }

    return (
        <div class="restOfScreenForSumateScreen">
            <Container> 
                    <div class="col center">
                        <Card className={classes.card}>
                        <CardContent className={classes.grid}>
                            <Grid
                                container
                                spacing={0}
                                alignItems='center'
                                justify='center'
                                textOverflow='hidden'
                                className={classes.grid}
                                >
                                <Table   className={classes.table}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align='center'>
                                                <Typography className={classes.title} variant='h4'>
                                                    ¡Súmate!
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell  align='center'>
                                                <Typography className={classes.h6} >
                                                    ¿Te gusta lo que hacemos? ¿Quieres formar parte de un equipo increíble? 
                                                    ¿Te gusta la idea de ayudar a preservar la naturaleza y los hermosos seres que la integran? 
                                                    Hay muchas formas para poder hacerlo y ¡Sólo depende de ti!
                                                    Necesitamos personas que colaboren en:
                                                    <Typography className={classes.h6} >  <br></br> </Typography>
                                                    <Typography className={classes.h6} >
                                                    <li><b>Rescates de animales silvestres.</b></li>
                                                    </Typography>
                                                    <Typography className={classes.h6} >
                                                    <li><b>Guardias y monitoreo de playas.</b></li>
                                                     </Typography>
                                                     <Typography className={classes.h6} >
                                                     <li><b>Traslados de animales.</b></li>
                                                     </Typography>
                                                     <Typography className={classes.h6} >
                                                     <li><b>Colaboración en eventos.</b></li>
                                                     </Typography>
                                                     <Typography className={classes.h6} >
                                                     <li><b>Mantenimiento de infraestructura en donde se recupera nuestra fauna 
                                                     (creamos recintos adaptados para las distintas especies, en donde puedan recuperarse 
                                                     lo más rápido y confortables posible).</b></li>
                                                     </Typography>
                                                     <Typography className={classes.h6} >
                                                     <li><b>Educación: dictamos charlas y talleres para que todos conozcan nuestra fauna y flora, 
                                                     ¡es la mejor manera de protegerlas!</b></li> 
                                                    </Typography>
                                                    <Typography className={classes.h6} >
                                                     <li><b>Comunicación: presencia en eventos, difusión en general.</b></li> 
                                                    </Typography>
                                                    <Typography className={classes.h6} >
                                                     <li><b>Investigación: realizamos trabajos científicos que nos permitan conocer mucho más a 
                                                     la naturaleza en su conjunto. Tenemos trabajos de ciencia ciudadana para tí.</b></li> 
                                                    </Typography>
                                                    </Typography>
                                                    <Typography className={classes.h6} >
                                                    <Typography className={classes.h6} >  <br></br> </Typography>
                                                    Siempre hacemos capacitaciones específicas antes de comenzar a integrarte en el área que elijas. 
                                                    Aceptamos voluntarios menores de 18 años con el consentimiento y acompañamiento de sus padres/tutores.
                                                    ¡Anímate! Si quieres sumarte a nuestro equipo de trabajo, envíanos un mail a <b>info@socobioma.org </b> 
                                                    con tus datos personales, ubicación y tareas de interés, así podremos contactarte a la brevedad.
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='center'>
                                                <Avatar  alt='' src={'/images/sumate/sumate_1.jpg'} className={classes.bigAvatar} />
                                                <Avatar  alt='' src={'/images/sumate/sumate_2.jpg'} className={classes.bigAvatar} />
                                                <Avatar  alt='' src={'/images/sumate/sumate_3.jpg'} className={classes.bigAvatar} />
                                                <Avatar  alt='' src={'/images/sumate/sumate_4.jpg'} className={classes.bigAvatar} />
                                            </TableCell>
                                        </TableRow>
                                       
                                    </TableBody>
                                </Table>
                            </Grid>
                        </CardContent>
                        </Card>
                    </div>
                   
          
                <SuccessMessage open={openSuccess} handleOpen={() => {
                    setOpenSuccess(!openSuccess)
                }} message={message} handleCloseMsj={handleCloseMsj} />
            </Container>
        </div>
            )


}