/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React from 'react'
import { render } from "react-dom";
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import 'react-responsive-ui/style.css'
import MuiPhoneNumber from 'material-ui-phone-number'
import './style.css'
import ErrorMsg from './ErrorMsg'
import SuccessMessage from './SuccessMessage'
import { SocobiomaTextField, SocobiomaSelectProveedor, SocobiomaMonthDatePicker, SocobiomaSelect, SocobiomaGridList, SocobiomaInputFotos, SocobiomaButton } from '../../socobioma_components'
import { usePromiseTracker, trackPromise } from 'react-promise-tracker'
import LoadingSpinner from '../../generalComponents/loadingSpinner/LoadingSpinner.js'
import Typography from '@material-ui/core/Typography'
import './ScreenColabora.css'
import iconBROU from './brou.png'
import iconRedPagos from './redpagos.png'
import iconPayPal from './paypal-logo-4.png'
import iconOca from './oca.png'
import iconVisa from './visa.png'
import mercadoPago from './MP_QR.jpeg'
import donarOnline from './donaronlineLogo.svg'
import { Redirect } from 'react-router-dom'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import Table from '@material-ui/core/Table'
import Avatar from '@material-ui/core/Avatar'
const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
    height: 'auto',
    width: '100%'
  },
  textField: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '20px',
    marginBottom: 'auto',
    height: 'auto',
    width: '100%'
  },
  radioButton: {
    margin: 'auto'
  },
  button: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '40px',
    marginBottom: 'auto',
    maxWidth: '842px',
    height: 'auto',
    width: '100%',
    backgroundColor: 'white'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  formControl: {
    backgroundColor: '#FCFAFA',
    margin: theme.spacing(1),
    width: '100%'
  },
  card: {
    width: '100%',
    maxWidth: '750px',
    margin: '5px 5px 5px 5px',
    backgroundColor: 'rgba(255, 255, 255, 0.93)'
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto'
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2)
  },
  title: {
    color: '#3C810D'
  },
  h6: {size: '1.132em',
    textAlign:'justify',
    fontFamily:'Source Sans Pro'
},
 bigAvatar: {
        maxWidth: '900px',
        height: '91%',
        display: 'inline-block',
        marginTop: '3%',
        margin: '5px 5px 5px 5px',
        borderColor: '#9FCC3C',
        width: 158,
        height: 158,
        backgroundColor: 'transparent'
        
    }
}))

export default function ScreenColabora (props) {
  const classes = useStyles()
  //const positionY = window.pageYOffset
  //const positionX = window.pageXOffset
  const iconoSidebar = { maxWidth: 40, maxHeight: 60  }
  const [redirect, setRedirect] = React.useState(false)
  const [openError, setOpenError] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [openSuccess, setOpenSuccess] = React.useState(false)
  const [message, setMessage] = React.useState([])
  const [departamentos, setDepartamentos] = React.useState([])
  const apiURL = process.env.REACT_APP_API_URL
  const proveedorTarjeta = ['VISA', 'OCA']
  const moneda = ['$', 'USD']
  const monedaTags = ['PESOS', 'DOLARES']
  const [verFormulario, setVerFormulario] = React.useState(false)
  const [dataRedirect, setdataRedirect] = React.useState({ path: '/', msj: null, push: true })
  const [tarjeta, setTarjeta] = React.useState({
    nombreAportante: null,
    cedulaIdentidadAportante: null,
    departamentoAportante: null,
    direccionAportante: null,
    telefonoAportante: null,
    emailAportante: null,
    proveedorTarjetaAportante: null,
    numeroTarjetaAportante: null,
    fechaVencimientoTarjetaAportante: null,
//    codigoSeguridadAportante: null,
    monedaAportante: null,
    montoAportante: null
  })
  
   function limpiarFormulario () {
    setError(false)
    setOpenSuccess(false)
    setOpenError(false)
    setTarjeta({
    ...tarjeta,
    nombreAportante: null,
    cedulaIdentidadAportante: null,
    departamentoAportante: null,
    direccionAportante: null,
    telefonoAportante:null,
    emailAportante: null,
    proveedorTarjetaAportante: null,
    numeroTarjetaAportante: null,
    fechaVencimientoTarjetaAportante: null,
//    codigoSeguridadAportante: null,
    monedaAportante: null,
    montoAportante: null
    })
  }
  const [notifyEmail, setNotifyEmail] = React.useState(false)

  const handleChange = attribute => event => {
    setTarjeta({ ...tarjeta, [attribute]: event.target.value })
  }
  
  const handleChangeDate = attribute => date => {
    setTarjeta({ ...tarjeta, [attribute]: date.getTime() })
  }
  
  const postReport = function (event) {
    var positionY = window.pageYOffset
    var positionX = window.pageXOffset
    if (tarjeta.nombreAportante === null ||  tarjeta.cedulaIdentidadAportante === null || 
        tarjeta.departamentoAportante === null || tarjeta.direccionAportante === null || 
      (tarjeta.emailAportante !== null && !tarjeta.emailAportante.match('.+@.+'))  ||
      tarjeta.proveedorTarjetaAportante === null || tarjeta.numeroTarjetaAportante === null || 
      tarjeta.fechaVencimientoTarjetaAportante == null  ||
      tarjeta.monedaAportante === null || tarjeta.montoAportante === null  || tarjeta.telefonoAportante === null) {
      setError(true)
      
      
      window.scrollTo(positionX,positionY)
      
      setMessage('Por favor complete los campos obligatorios (*) y vuelva a enviar su reporte')
      setOpenError(true)
    } else {
      var url = `${apiURL}/colaborar`
      trackPromise(
        axios.post(url, tarjeta).then(response => {
            limpiarFormulario()
            setMessage('Sus datos han sido enviados correctamente. ¡Gracias por colaborar!')
            setOpenSuccess(true)
            setOpenError(false)
//          setdataRedirect({ path: '/', msj: 'Sus datos han sido enviados correctamente. ¡Gracias por colaborar!' })
//          setRedirect(true)
        }).catch(responseError => {
          console.log(responseError)
          console.log(`${apiURL}/colaborar`)
          setMessage('Hubo un error con la página, inténtelo más tarde')
          setOpenError(true)
        })
      )
    }
  }

  React.useEffect((event) => {
    const url = `${apiURL}/departamentos`
    trackPromise(
      axios.get(url).then((response) => {
        setDepartamentos(response.data.map((obj) => {
          return obj.nombre
        }))
      }).catch(_error => {
        console.log('Error al ingresar el reporte: \n', _error) // TODO show errors to the user
      })
    )
  }, [])

  function handleChangePhone (value) {
    setTarjeta({ ...tarjeta, telefonoAportante: value })
  }

  function handleCorreoToggle (event) {
    setNotifyEmail(!notifyEmail)
  }

  function handleChangeEmail (event) {
    setTarjeta({ ...tarjeta, emailAportante: event.target.value.trim() })
  }
  
  function handleVerFormulario (event) {
    setVerFormulario(!verFormulario)
  }
  React.useEffect((event) => {
    if (!notifyEmail) {
      setTarjeta({ ...tarjeta, emailAportante: null })
    }
  }, [notifyEmail])

  const cancelar = function () {
    window.scrollTo(0, 0)
//    setRedirect(true)
  }

  const { promiseInProgress } = usePromiseTracker()

  return (
<div class="restOfScreenForColaboraScreen">
    <div className={classes.container}>
      <Card className={classes.card}>
        <ErrorMsg open={openError} onHandleOpen={() => { setOpenError(!openError) }} message={message} />
        <SuccessMessage open={openSuccess} handleOpen={() => { setOpenSuccess(!openSuccess) }} message={message} />
        {promiseInProgress
            ? (
              <CardContent>
                <LoadingSpinner />
              </CardContent>
            )
            : (
              <CardContent>
       <Table   className={classes.table}>
              <TableBody>
                <Typography align='center' className={classes.title} variant='h4'>
                  ¡Apóyanos!
                </Typography>
                <TableRow>
                    <TableCell align='center'>
                        <Typography className={classes.title} variant='subtitle2' align='justify'>
                        <em><b>Con tu ayuda podemos recuperar más animales silvestres, plantar más árboles y llegar a más personas con nuestras charlas. </b> </em>
                        </Typography>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell  align='center'>
                        <Typography className={classes.h6} >
                        Haz click aquí
                        <Checkbox
                            checked={verFormulario}
                            onChange={handleVerFormulario}
                            value={verFormulario}
                            color='primary'
                          />
                            para desplegar el formulario y
                            convertirte en socio colaborador por <b> <img style={iconoSidebar} src={iconOca} /> </b>   o <b> <img style={iconoSidebar} src={iconVisa} /> </b> .
                            Los socios de SOCOBIOMA, tienen descuentos en los cursos y talleres que organice la ONG. 
                        </Typography>
                    </TableCell>
                </TableRow>                
                <TableRow>
                
                    {verFormulario &&
                      
                <TableCell align='center'>
                <form className={classes.container} noValidate autoComplete='off'>
                  <List className={classes.root}>
                    <ListItem id='name'>
                      <SocobiomaTextField
                        label='Nombre completo del titular'
                        maxLength={60}
                        value={tarjeta.nombreAportante}
                        placeholder='Ingrese su nombre completo'
                        onChange={handleChange('nombreAportante')}
                      />
                    </ListItem>
                    <ListItem id='class'>
                      <SocobiomaTextField
                        label='Cédula de identidad*'
                        value={tarjeta.cedulaIdentidadAportante}
                        placeholder='12345678 (Sin guión con dígito verificador)'
                        onChange={handleChange('cedulaIdentidadAportante')}
                        maxLength={9}
                      />
                    </ListItem>
                    <ListItem id='commonName'>
                    <SocobiomaSelect
                        label='Departamento*'
                        value={tarjeta.departamentoAportante}
                        onChange={handleChange('departamentoAportante')}
                        error={tarjeta.departamentoAportante === null && error}
                        items={departamentos}
                      />
                        <SocobiomaTextField
                          label='Dirección*'
                          value={tarjeta.direccionAportante}
                          onChange={handleChange('direccionAportante')}
                          maxLength={60}
                        />
                    </ListItem>
                    <ListItem id='phoneNumber'>
                      <MuiPhoneNumber
                        name='phone'
                        maxLength={45}
                        label='Teléfono*'
                        data-cy='user-phone'
                        regions='south-america'
                        defaultCountry='uy'
                        countryCodeEditable={false}
                        autoFormat={false}
                        fullWidth='true'
                        value={tarjeta.telefonoAportante}
                        onChange={handleChangePhone}
                        className={classes.phoneNumber}
                      />
                    </ListItem>
                      <ListItem id='email'>
                        <SocobiomaTextField
                          maxLength={60}
                          error={!(tarjeta.emailAportante !== null && tarjeta.emailAportante.match('.+@.+')) && error}
                          label='Correo'
                          value={tarjeta.emailAportante}
                          placeholder='ejemplo@socobioma.com'
                          onChange={handleChangeEmail}
                        />
                      </ListItem>  
                    <ListItem id='numeroTarjeta'>
                    <SocobiomaSelectProveedor
                        label='Proveedor de tarjeta*'
                        value={tarjeta.proveedorTarjetaAportante}
                        onChange={handleChange('proveedorTarjetaAportante')}
                        error={tarjeta.proveedorTarjetaAportante === null && error}
                        items={proveedorTarjeta}
                        itemsTags={proveedorTarjeta}
                        />
                      <SocobiomaTextField
                        label='Nº tarjeta'
                        maxLength={16}
                        value={tarjeta.numeroTarjetaAportante}
                        placeholder='xxxx-xxxx-xxxx-xxxx'
                        onChange={handleChange('numeroTarjetaAportante')}
                      />
                    </ListItem>
                    <ListItem>
                    <SocobiomaMonthDatePicker
                      onChange={handleChangeDate('fechaVencimientoTarjetaAportante')}
                      maxDate={new Date()}
                      value={tarjeta.fechaVencimientoTarjetaAportante}
                      label='Fecha de vencimiento'
                    />

                  </ListItem>
                  <ListItem>
                    <SocobiomaSelect
                        label='Moneda*'
                        value={tarjeta.monedaAportante}
                        onChange={handleChange('monedaAportante')}
                        error={tarjeta.monedaAportante === null && error}
                        items={monedaTags}
                        itemsTags={moneda}
                    />                      
                    <SocobiomaTextField
                        label='Monto'
                        maxLength={16}
                        value={tarjeta.montoAportante}
                        onChange={handleChange('montoAportante')}
                    />
                  </ListItem>                  
                    <ListItem>
                      <SocobiomaButton
                        label='Cancelar' onClick={cancelar}
                      />
                      <SocobiomaButton
                        label='Enviar' onClick={postReport}
                      />
                    </ListItem>
                  </List>
                </form>
                 </TableCell>
                 }
                </TableRow>
                <TableRow style={{textAlign:'center'}}>
                    <Typography className={classes.h6} style={{textAlign:'left'}}>
                    <b>También podemos recibir tu apoyo por las siguientes vías de colaboración:</b>
                    </Typography>
                    <TableCell  align='center' className={classes.bigAvatar}>                 
                        <Typography className={classes.h6} style={{textAlign:'center'}}>
                            <img style={{maxHeight:'30px'}} src={iconBROU} />
                        </Typography>
                        <Typography className={classes.h6} style={{textAlign:'center'}}>
                            <b>Caja de ahorro pesos</b>
                        </Typography>
                        <Typography className={classes.h6} style={{textAlign:'center'}}> 
                            <b>110162362-00001 </b>
                        </Typography>
                    </TableCell>
                    <TableCell  align='center' className={classes.bigAvatar}>
                        <Typography className={classes.h6} style={{textAlign:'center'}}>
                            <img style={{maxHeight:'30px'}} src={iconRedPagos} />
                        </Typography>
                        <Typography className={classes.h6} style={{textAlign:'center'}} >
                            <b>  Colectivo cuenta 70681</b>
                        </Typography>
                        <Typography className={classes.h6} style={{textAlign:'center'}} >
                            <a href="https://donaronline.org/socobioma/socio-colaborador">
                                <input style={{maxHeight:'90px'}} type="image" src={donarOnline} border="0" name="submit" title="DonarOnline la forma más fácil de donar" alt="Donate with DonarOnline button"  />
                            </a>
                        </Typography>
                    </TableCell>                   
                    <TableCell  align='center' className={classes.bigAvatar}>
                        <p/>
                        <Typography className={classes.h6} style={{textAlign:'center'}} >
                            <b> <img style={{maxHeight:'30px'}} src={iconPayPal} /> </b>   
                            <form action="https://www.paypal.com/donate" method="post" target="_top">
                            <input type="hidden" name="hosted_button_id" value="5R2CA6WDZK5S8" />
                            <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button"  />
                            <img alt="" border="0" src="https://www.paypal.com/en_UY/i/scr/pixel.gif" width="1" height="1" />
                            </form>
                        </Typography>
                    </TableCell>
                    <TableCell  align='center' className={classes.bigAvatar}>
                        <img  src={mercadoPago} />
                    </TableCell>
                    </TableRow>
           
                </TableBody>
                </Table>
              </CardContent>
            )}
      </Card>
    </div>

    </div>
  )
}

