import React from 'react'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import SelectDep from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import GridListTileBar from '@material-ui/core/GridListTileBar'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import PhotoCamera from '@material-ui/icons/PhotoCamera'
import Button from '@material-ui/core/Button'
import { MuiPickersUtilsProvider, KeyboardDatePicker, DateTimePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Card from '@material-ui/core/Card'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import ListItemIcon from '@material-ui/core/ListItemIcon'
// import { fontFamily } from '@material-ui/system'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'

const iconoSidebar = { maxWidth: 25, maxHeight: 25}
const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
    height: 'auto',
    width: '100%',
    fontSize: '22px'
  },
  select:
  {
      height:'56px'
  },
  textField: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '20px',
    marginBottom: 'auto',
    height: 'auto',
    width: '100%'
  },
  textArea: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '20px',
    marginBottom: 'auto',
    height: 'auto',
    width: '100%'
  },
  radioButton: {
    margin: 'auto'
  },
  button: {
    marginLeft: '5px',
    marginRight: '5px',
    marginTop: '15px',
    marginBottom: 'auto',
    height: 'auto',
    width: '100%',
    backgroundColor: 'white'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  formControl: {
    backgroundColor: '#FCFAFA',
    margin: theme.spacing(1),
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '20px',
    marginBottom: 'auto',
    height: 'auto'
  },
  card: {
    width: '100%',
    maxWidth: 750,
    backgroundColor: 'rgba(255, 255, 255, 0.8)'
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto'
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2)
  },

  buttonFotos: {
    margin: theme.spacing(0)
  },
  inputFotos: {
    display: 'none'
  },
  gridList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
    width: '100%',
    col: 2
  },
  title: {
    color: '#f44336'
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
  },
  expansion_title: {
    marginTop: 'auto',
    marginBottom: 'auto',
    color: '#3C810D'
  },
  card_expansion: {
    marginTop: '20px',
    width: '100%',
    maxWidth: 750,
    backgroundColor: 'rgba(255, 255, 255, 0.8)'
  }
}))

export function SocobiomaTextField (props) {
  const classes = useStyles()
  return (
    <TextField
        InputProps={{
              className: classes.select,
              maxLength: (props.maxLength || 500) 
            }}
      id='filled-multiline-static'
      multiline
      rows={props.rows || '1'}
      margin='normal'
      variant='outlined'
      label={props.label}
      defaultValue={props.defaultValue}
      value={props.value}
      endAdornment={props.endadornment}
      type={props.type}
      placeholder={props.placeholder}
      onChange={props.onChange}
      error={props.error}
      className={classes.textField}
      onBlur={props.onBlur}
    />
  )
}

export function SocobiomaBigTextField (props) {
  const classes = useStyles()
  return (
    <TextField
      id='filled-multiline-static'
      inputProps={{ maxLength: (props.maxLength || 500) }}
      multiline
      rows={props.rows || '1'}
      margin='normal'
      variant='outlined'
      label={props.label}
      defaultValue={props.defaultValue}
      value={props.value}
      endAdornment={props.endadornment}
      type={props.type}
      placeholder={props.placeholder}
      onChange={props.onChange}
      error={props.error}
      className={classes.textField}
      onBlur={props.onBlur}
    />
  )
}

export function SocobiomaTextArea (props) {
  const classes = useStyles()
  return (
    <TextareaAutosize
      id='filled-multiline-static'
      inputProps={{ maxLength: (props.maxLength || 500) }}
      multiline
      rows={props.rows || '1'}
      margin='normal'
      variant='filled'
      label={props.label}
      value={props.value}
      endAdornment={props.endadornment}
      type={props.type}
      placeholder={props.placeholder}
      onChange={props.onChange}
      error={props.error}
      className={classes.textField}
      onBlur={props.onBlur}
    />
  )
}
export function SocobiomaNumbersField (props) {
  const classes = useStyles()
  return (
    <TextField
      id='standard-number'
      label={props.label}
      variant='outlined'
      value={props.value}
      placeholder={props.placeholder}
      onChange={props.onChange}
      type='number'
      onBlure={props.onBlur}
      error={props.error}
      InputLabelProps={{
        shrink: true
      }}
      margin='normal'
      inputProps={props.nputProps}
      className={props.styleTextfield ? props.styleTextfield : classes.textField}
      onBlur={props.onBlur}
      disabled={props.disabled}
    />
  )
}
const iconos = ['iconos/visa-pay-logo.png', 'iconos/oca.png']
export function SocobiomaSelectProveedor (props) {
  const classes = useStyles()
  const inputLabel = React.useRef(null)
  const [labelWidth, setLabelWidth] = React.useState(0)

  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])
  return (
    <FormControl variant='outlined' className={props.selectStyle ? props.selectStyle : classes.formControl}>
      <InputLabel ref={inputLabel} htmlFor='outlined-age-simple'>
        {props.label}
      </InputLabel>
      <SelectDep
        value={props.value}
        onChange={props.onChange}
        error={props.error}
        labelWidth={labelWidth}
        disabled={props.disabled}
      >
        {props.items && props.items.map((item, index) => {
          return (
            <MenuItem key={item} value={item}>
             
             <ListItemIcon>
                    <img style={iconoSidebar}  src={'/' + iconos[index]} />
                  </ListItemIcon>

            </MenuItem>
          )
        })}
      </SelectDep>
    </FormControl>
  )
}
export function SocobiomaSelect (props) {
  const classes = useStyles()
  const inputLabel = React.useRef(null)
  const [labelWidth, setLabelWidth] = React.useState(0)

  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])
  return (
    <FormControl variant='outlined' className={props.selectStyle ? props.selectStyle : classes.formControl}>
      <InputLabel ref={inputLabel} htmlFor='outlined-age-simple'>
        {props.label}
      </InputLabel>
      <SelectDep
        value={props.value}
        onChange={props.onChange}
        error={props.error}
        labelWidth={labelWidth}
        disabled={props.disabled}
      >
        {props.items && props.items.map((item, index) => {
          return (
            <MenuItem key={item} value={item}>
              {props.itemsTags ? props.itemsTags[index] : item}
            </MenuItem>
          )
        })}
      </SelectDep>
    </FormControl>
  )
}

export function SocobiomaGridList (props) {
  const classes = useStyles()
  return (
    <>
      {props.items &&
        <GridList className={classes.gridList} cols={props.col || 2.5}>
          {props.items.map(tile => (
            <GridListTile key={tile}>
              <img src={`data:image/jpeg;base64,${tile}`} alt=' ' />
              {!props.noDeleteOption &&
                <GridListTileBar
                  classes={{
                    root: classes.titleBar,
                    title: classes.title
                  }}
                  actionIcon={
                    <IconButton onClick={(e) => props.onChange(tile)}>
                      <DeleteIcon className={classes.title} />
                    </IconButton>
                  }
                />}
            </GridListTile>
          ))}
        </GridList>}
    </>
  )
}

export function SocobiomaInputFotos (props) {
  const classes = useStyles()
  return (
    <div>
      <input
        accept='image/*' className={classes.inputFotos} id={props.id} multiple={false}
        type='file' onChange={props.onChange}
      />
      <label htmlFor={props.id}>
        <IconButton
          onChange={props.onChange}
          color='primary'
          className={classes.buttonFotos}
          aria-label='upload picture'
          component='span'
        >
          <PhotoCamera />
        </IconButton>
      </label>
    </div>
  )
}

export function SocobiomaDatePicker (props) {
  const classes = useStyles()
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        onKeyDown={(e) => e.preventDefault()}
        className={classes.textField}
        margin='normal'
        id='date-picker-dialog'
        label={props.label}
        format='dd/MM/yyyy'
        maxDate={props.maxDate}
        value={props.value}
        onChange={props.onChange}
        inputVariant='outlined'
        InputAdornmentProps={{ position: 'start' }}
        allowKeyboardControl='false'
        error={props.error}
      />
    </MuiPickersUtilsProvider>

  )
}

export function SocobiomaMonthDatePicker (props) {
  const classes = useStyles()
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>

      <KeyboardDatePicker
       views={['month','year']}
        onKeyDown={(e) => e.preventDefault()}
        className={classes.textField}
        margin='normal'
        id='date-picker-dialog'
        label={props.label}
        format='MM/yyyy'
        value={props.value}
        onChange={props.onChange}
        inputVariant='outlined'
        InputAdornmentProps={{ position: 'start' }}
        allowKeyboardControl='false'
        error={props.error}
      />
    </MuiPickersUtilsProvider>

  )
}

export function SocobiomaButton (props) {
  const classes = useStyles()
  return (
    <Button
      onChange={props.onChange}
      onClick={props.onClick}
      variant='outlined'
      color='primary'
      className={classes.button}
    >
      {props.label}
    </Button>
  )
}

export function SocobiomaDateTimePicker (props) {
  const classes = useStyles()
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DateTimePicker
        format='dd/MM/yy HH:mm'
        label={props.label}
        inputVariant='outlined'
        value={props.value}
        maxDate={props.maxDate}
        onChange={props.onChange}
        className={classes.textField}
        margin='normal'
        error={props.error}
        ampm={false}
      />
    </MuiPickersUtilsProvider>
  )
}

export function SocobiomaExpansion (props) {
  const classes = useStyles()
  return (
    <Card className={classes.card_expansion}>
      <ExpansionPanel className={classes.root}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <h5 align='left' className={classes.expansion_title}>{props.resumen}</h5>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Table className={classes.root}>
            <TableBody>
              {props.valor1 &&
                <TableRow>
                  <TableCell align='left'>{props.titulo1}: </TableCell>
                  <TableCell align='left'>{props.valor1}</TableCell>
                </TableRow>}
              {props.valor2 &&
                <TableRow>
                  <TableCell align='left'>{props.titulo2}: </TableCell>
                  <TableCell align='left'>{props.valor2}</TableCell>
                </TableRow>}
              {props.valor3 &&
                <TableRow>
                  <TableCell align='left'>{props.titulo3}: </TableCell>
                  <TableCell align='left'>{props.valor3}</TableCell>
                </TableRow>}
              {props.valor4 &&
                <TableRow>
                  <TableCell align='left'>{props.titulo4}: </TableCell>
                  <TableCell align='left'>{props.valor4}</TableCell>
                </TableRow>}
              {props.valor5 &&
                <TableRow>
                  <TableCell align='left'>{props.titulo5}: </TableCell>
                  <TableCell align='left'>{props.valor5}</TableCell>
                </TableRow>}
              {props.valor6 &&
                <TableRow>
                  <TableCell align='left'>{props.titulo6}: </TableCell>
                  <TableCell align='left'>{props.valor6}</TableCell>
                </TableRow>}
            </TableBody>
          </Table>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Card>
  )
}

export function SocobiomaCardTitle (props) {
  const classes = useStyles()
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        onKeyDown={(e) => e.preventDefault()}
        className={classes.textField}
        margin='normal'
        id='date-picker-dialog'
        label={props.label}
        format='dd/MM/yyyy'
        value={props.value}
        onChange={props.onChange}
        inputVariant='outlined'
        InputAdornmentProps={{ position: 'start' }}
        allowKeyboardControl='false'
      />
    </MuiPickersUtilsProvider>

  )
}
