// TODO contendor pagina conozcanos.
// trae informacion del backend y llama a los componentes de presentacion pasandoles los datos pertinentes
import React from 'react'
import { render } from "react-dom";

import SuccessMessage from '../../generalComponents/Message/SuccessMessage'

import {  animateScroll as scroll } from "react-scroll";
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Pagination from 'material-ui-flat-pagination'
import Grid from '@material-ui/core/Grid'
import { ACCESS_TOKEN } from '../../../constants'
import { usePromiseTracker, trackPromise } from 'react-promise-tracker'
import LoadingSpinner from '../../generalComponents/loadingSpinner/LoadingSpinner.js'
import { ANIMAL_FILE_STATE } from '../../../constants/index'
import ErrorMsg from '../../generalComponents/Message/ErrorMessage'
import LinesEllipsis from 'react-lines-ellipsis'
import { Link } from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar'
import autoLogout from '../../../generalFunctions/autoSignout'


import Carousel from "../../generalComponents/Carousel/Carousel";
import "react-responsive-carousel/lib/styles/carousel.css";
import { Container, Row, Col } from "reactstrap";
import "./ScreenConozcanos.css";
const useStyles = makeStyles({
    card: {
        maxWidth: '445px',
        height: '95%',
        display: 'inline-block',
        marginTop: '5%',
        margin: '5px 5px 5px 5px',
        backgroundColor: 'rgba(255, 255, 255, 0.93)',

    },
    cardTitleSpinner: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 'auto',
        marginBottom: 'auto',
        width: '100%',
        maxWidth: 1448,
        backgroundColor: 'rgba(255, 255, 255, 0.8)'
    },
    table: {
        padding: '9px 3px 9px 3px',
        margin: '3px',
        height: '40%'

    },
    text: {
        wordBreak: 'break-word',
        maxWidth: '100px'
    },
    textLong: {
        wordBreak: 'break-word',
        maxWidth: '100px'
    },
    fecha: {
        whiteSpace: 'nowrap'
    },
    hover: {
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, .8)'
        }
    },
    filter: {
        backgroundColor: 'rgba(255, 255, 255, 1)',
        position: 'fixed',
        top: '10',
        right: '0',
        '&:active': {
            backgroundColor: 'rgba(255, 255, 255, 1)'
        },
        '&:focus': {
            backgroundColor: 'rgba(255, 255, 255, 1)'
        },
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 1)',
            borderColor: '#3EA91D'
        }
    },
    checkboxIcons: {
        color: '#20DD03'
    },
    checkboxIconsRed: {
        color: '#F53232'
    },
    checkboxText: {
        paddingLeft: '15px',
        color: '#757575'
    },
    checkboxContainer: {
        display: 'flex',
        alignContent: 'space-between'
    },
    root: {
        maxWidth: '261px',
        width: '100%',
        padding: '0px'
    },
    grid: {
        height: '100%',

    },
    title: {
        color: '#3C810D'
    },
    h6: {size: '1.132em',
    textAlign:'justify',
    fontFamily:'Source Sans Pro'},

    h7: {
    color: '#3C810D',
    fontFamily:'Source Sans Pro'},
    bigAvatar: {
        maxWidth: '900px',
        height: '91%',
        display: 'inline-block',
        marginTop: '3%',
        margin: '5px 5px 5px 5px',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        width: 158,
        height: 158
    }
})

export default function ScreenConozcanos() {
    const [message, setMessage] = React.useState([])
    const [openSuccess, setOpenSuccess] = React.useState(false)
    const classes = useStyles()


    function handleCloseMsj() {
        setOpenSuccess(false)
    }

    return (
        <div class="restOfScreenForConozcanosScreen">
            <Container> 
                    <div class="col left">
                        <Card className={classes.card}>
                        <CardContent className={classes.grid}>
                            <Grid
                                container
                                spacing={0}
                                alignItems='center'
                                justify='center'
                                textOverflow='hidden'
                                className={classes.grid}
                                >
                                <Table   className={classes.table}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align='center'>
                                                <Typography className={classes.title} variant='h5'>
                                                    ¿Quiénes somos?
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='center'>
                                                <Typography className={classes.h7} variant='subtitle2'>
                                                <em><b>SO.CO.BIO.MA.: Sociedad para la Conservación de la Biodiversidad de Maldonado </b> </em>
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell  align='center'>
                                                <Typography className={classes.h6} >
                                                    Somos una organización sin fines de lucro (Personería Jurídica Nº 10.954, Centro de Rescate y Rehabilitación Habilitado por DINAMA-MVOTMA R/DN/0098/20) que nació en 2006 en Maldonado, Uruguay. 
                                                    Integrada por voluntarios, profesionales y personas de la comunidad que buscan proteger la biodiversidad.
                                                    Recibimos más de 300 animales silvestres al año que requieren cuidados para volver a su hábitat 
                                                    (Resolución/Dirección Nacional de Medio Ambiente 0098/2020 de Fauna). Realizamos charlas y talleres 
                                                    en centros educativos transmitiendo conocimientos sobre nuestra fauna, con énfasis en la problemática ambiental, 
                                                    estrechamente vinculada a los ingresos de animales para su rehabilitación, evaluando causas, consecuencias y acciones correctivas, 
                                                    para que entre todos logremos mediante nuestros cambios de conducta, un mundo más amigable.
                                                    Promovemos el cuidado y conocimiento de los montes nativos, por medio de plantaciones en instituciones públicas 
                                                    y en los hogares de cada persona que quiera colaborar para restaurar nuestros ecosistemas.
                                                    Apuntamos desde nuestro trabajo a generar una conciencia colectiva que permita la conservación de la 
                                                    biodiversidad y promueva el respeto por todos los seres que habitan el planeta.
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                        </CardContent>
                        </Card>
                    </div>
                    <div class="col right">
                        <Card className={classes.card}>
                        <CardContent className={classes.grid}>
                            <Grid
                                container
                                spacing={0}
                                alignItems='center'
                                justify='center'
                                textOverflow='hidden'
                                className={classes.grid}>
                                <Table   className={classes.table}>
                                <TableBody>
                                <TableRow>
                                    <TableCell align='center'>
                                        <Typography className={classes.title} variant='h5'>
                                            Nuestra misión
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='center'>
                                        <Typography className={classes.h7} variant='subtitle1'>
                                        <em><b> Recuperación de animales nativos, educación, investigación</b> </em>
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell  align='center'>                                    
                                    <Typography className={classes.h6} >
                                            SOCOBIOMA tiene como misión rescatar animales silvestres nativos que necesitan ayuda, 
                                            para rehabilitarlos y posteriormente devolverlos a su hábitat. Generar charlas y talleres 
                                            educativos como herramienta para impactar positivamente en la conservación del medio ambiente, 
                                            a través de la difusión del conocimiento de nuestras especies nativas. Desarrollar investigación 
                                            que genere insumos para la creación de un cambio social y lineamientos de acción que promuevan 
                                            la conservación de la biodiversidad.
                                    </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='center'>
                                        <Avatar  alt='' src={'/images/conozcanos/conozcanos_1.png'} className={classes.bigAvatar} />
                                    </TableCell>
                                </TableRow>
                                </TableBody>
                                </Table>
                            </Grid>
                        </CardContent>
                        </Card>
                    </div>
          
                <SuccessMessage open={openSuccess} handleOpen={() => {
                    setOpenSuccess(!openSuccess)
                }} message={message} handleCloseMsj={handleCloseMsj} />
            </Container>
        </div>
            )


}