import React from 'react'
import { Card, CardContent, AppBar, Tabs, Tab, DialogActions } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Slide from '@material-ui/core/Slide'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import '../../../constants/card.css'
import ListaTratamientos from './ListaTratamientos/ListaTratamientos.js'
import GridFichasDiarias from './GridFichasDiarias'
import axios from 'axios'
import { usePromiseTracker } from 'react-promise-tracker'
import { ACCESS_TOKEN } from '../../../constants'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Avatar from '@material-ui/core/Avatar'
import { SocobiomaButton } from '../../socobioma_components'
import { Link } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import NecropsiaFiles from '../screenArchivosNecropsia/ScreenArchivosNecropsia.js'
import autoLogout from '../../../generalFunctions/autoSignout'
import SuccessMessage from '../../generalComponents/Message/SuccessMessage'


import LoadingSpinner from '../../generalComponents/loadingSpinner/LoadingSpinner.js'
const useStyles = makeStyles(theme => ({
  card: {
    width: '100%'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  error: {
    color: '#F0072D'
  },
  title: {
    color: '#3C810D'
  },
  textLong: {
    wordBreak: 'break-word',
    maxWidth: '100%'
  },
  bigAvatar: {
    width: 110,
    height: 110,
  }
}))

export default function ScreenResumenAnimal(props) {
  const classes = useStyles()
  const config = { headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) } }
  const apiURL = process.env.REACT_APP_API_URL

  const [widthTable, setWidthTable] = React.useState(0)
  const [ficha, setFicha] = React.useState(props.location.state ? props.location.state.baseFicha : null)
  const [fotoPerfil, setFotoPerfil] = React.useState([])
  const [value, setValue] = React.useState('Tratamientos')
  const [enters, setEnters] = React.useState([true, true])
  const [openDialog, setOpenDialog] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(true)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  function convertDate(fecha) {
    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    const currentDate = iOS ? new Date(fecha.substr(0, 19)) : new Date(fecha)
    const formattedDate = (currentDate.getDate()).toString() + '/' + (currentDate.getMonth() + 1).toString() + '/' + currentDate.getFullYear().toString()
    return formattedDate
  }

  async function cargarFotoPerfil() {
    const headers = {
      'Content-Type': 'text/plain',
      Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN)
    }
    var promises = []
    var fotosId = []
    for (const link of ficha.fotoIdentificacion) {
      var url = `${apiURL}${link}`
      promises.push(axios.get(url, { headers }))
    }
    try {
      var responses = await Promise.all(promises)
      fotosId = (responses.map((foto) => { return foto.data }))
      setFotoPerfil(fotosId)
    } catch (err) {
      console.log('Error al traer fotos: \n', err)
    }
  }

  React.useEffect((event) => {
    if (ficha) {
      cargarFotoPerfil()
    }
  }, [ficha])

  React.useEffect(() => {
    const url = `${apiURL}/fichas_animal/${props.match.params.idFicha}`
    if (!ficha) {
      axios.get(url, config).then((response) => {
        setFicha(response.data)
        setLoading(false)
      }).catch((error) => {
        if (error.response.status === 401) {
          autoLogout()
        }
        setLoading(false)
        console.log(error)
      })
    } else {
      setLoading(false)
    }
  }, [])

  React.useEffect(() => {
    console.log(props.location.state)
    if (props.location.state && props.location.state.msj) {
      setOpen(true)
    }
  }, [])

  React.useEffect(() => {
    function updateSize() {
      setWidthTable(window.innerWidth - 100)
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  const volver = function () {
    window.history.go(-1)
    window.scrollTo(0, 0)
  }

  function convertText(texto) {
    return (texto) ? texto.replace(/_/g, ' ') : null
  }

  const handleClickOpen = () => {
    console.log('clicked')
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const { promiseInProgress } = usePromiseTracker()

  return (
    <div className={classes.container}>
      {(props.location.state && props.location.state.msj) && <SuccessMessage open={open} handleOpen={() => { setOpen(!open) }} message={props.location.state.msj} />}
      {loading
        ? (
          <CardContent>
            <LoadingSpinner />
          </CardContent>
        ) : ficha ? (
          <Card id='cardPrincipal' className={classes.card}>
            <CardContent>
              <div>
                <Typography align='center' className={classes.title} variant='h5'>
                  Ficha Animal # {ficha.idFichaAnimal}
                </Typography>
                <br></br>
              </div>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Link to={'/fichas/' + ficha.idFichaAnimal} underline='none'>
                        <Typography align='left' variant='subtitle2'>
                          Ver ficha de ingreso</Typography>
                      </Link>
                      <br></br>
                      <Link onClick={handleClickOpen} className='nav-link'>
                        <Typography align='left' variant='subtitle2'>
                          Ver archivos de necropsia </Typography>
                      </Link>

                    </TableCell>
                    <TableCell >
                      {fotoPerfil[0] &&
                        <Avatar alt='' src={`data:image/jpeg;base64,${fotoPerfil[0]}`} className={classes.bigAvatar} />
                      }
                    </TableCell>
                  </TableRow>



                  <Dialog onClose={handleCloseDialog} fullWidth aria-labelledby='necropsy-dialog-title' open={openDialog}>
                    <DialogTitle id='necropsy-dialog-title' onClose={handleCloseDialog}>
                      <Typography className={classes.title} align='center' variant='h5'>
                        {'Archivos de Necropsia'}
                      </Typography>
                      <Typography variant='h6' align='center'>
                        {'Ficha Animal #'} {ficha.idFichaAnimal}
                      </Typography>
                    </DialogTitle>
                    <DialogContent dividers>
                      <NecropsiaFiles idAnimal={ficha.idFichaAnimal} />
                    </DialogContent>
                    <DialogActions>
                      <SocobiomaButton onClick={handleCloseDialog} label='Cerrar' />
                    </DialogActions>
                  </Dialog>

                  {ficha.especie &&
                    <TableRow>
                      <TableCell>
                        <Typography variant='subtitle2'>
                          Especie:
                      </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='subtitle2'>
                          {ficha.especie}
                        </Typography>
                      </TableCell>
                    </TableRow>}
                  {ficha.estado &&
                    <TableRow>
                      <TableCell>
                        <Typography variant='subtitle2'>
                          Estado:
                      </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='subtitle2'>
                          {convertText(ficha.estado)}
                        </Typography>
                      </TableCell>
                    </TableRow>}
                  {ficha.fechaIngresoSocobiomaMald &&
                    <TableRow>
                      <TableCell>
                        <Typography variant='subtitle2'>
                          Fecha Ingreso:
                      </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='subtitle2'>
                          {convertDate(ficha.fechaIngresoSocobiomaMald)}
                        </Typography>
                      </TableCell>
                    </TableRow>}
                  {ficha.fechaEgreso &&
                    <TableRow>
                      <TableCell>
                        <Typography variant='subtitle2'>
                          Fecha Egreso:
                      </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='subtitle2'>
                          {convertDate(ficha.fechaEgreso)}
                        </Typography>
                      </TableCell>
                    </TableRow>}
                  {ficha.departamento &&
                    <TableRow>
                      <TableCell align='left'>
                        <Typography variant='subtitle2'>
                          Departamento:
                      </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='subtitle2'>
                          {ficha.departamento}
                        </Typography>
                      </TableCell>
                    </TableRow>}
                  {ficha.ubicacionActual &&
                    <TableRow>
                      <TableCell>
                        <Typography variant='subtitle2'>
                          Ubicación Actual:
                      </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='subtitle2' className={classes.textLong}>
                          {ficha.ubicacionActual}
                        </Typography>
                      </TableCell>
                    </TableRow>}
                </TableBody>
              </Table>
            </CardContent>
            <AppBar style={{ background: '#9FCC3C' }} position='static'>
              <Tabs variant='scrollable' value={value} onChange={handleChange} aria-label='wrapped label tabs example'>
                <Tab value='Tratamientos' label='Tratamientos' />
                <Tab value='Fichas diarias' label='Fichas diarias' />
              </Tabs>
            </AppBar>
            <Slide direction='right' in={enters[0] && value === 'Tratamientos'} onExited={() => setEnters([true, true])} onEntering={() => setEnters([true, false])} timeout={400} unmountOnExit mountOnEnter>
              <CardContent
                hidden={value !== 'Tratamientos'}
                class='container'
              >
                <ListaTratamientos promisePending={promiseInProgress} idFicha={props.match.params.idFicha} />
              </CardContent>
            </Slide>
            <Slide direction='right' in={enters[1] && value === 'Fichas diarias'} onExited={() => setEnters([true, true])} onEntering={() => setEnters([false, true])} timeout={400} unmountOnExit mountOnEnter>
              <CardContent
                hidden={value !== 'Fichas diarias'}
                class='container'
              >
                <GridFichasDiarias width={widthTable} ficha={props.match.params.idFicha} />
              </CardContent>
            </Slide>
          </Card>)
          : (
            <Card>
              <CardContent>
                <Typography align='center' className={classes.error} variant='h5'>
                  La ficha que usted solicita no se encuentra en el sistema.
              </Typography>
                <SocobiomaButton
                  label='Volver' onClick={volver}
                />
              </CardContent>
            </Card>)}
    </div>
  )
}
