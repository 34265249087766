import React from 'react'
import axios from 'axios'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'
import { SocobiomaExpansion, SocobiomaGridList, SocobiomaButton } from '../../socobioma_components'
import '../../../constants/card.css'
import Typography from '@material-ui/core/Typography'
import { usePromiseTracker, trackPromise } from 'react-promise-tracker'
import LoadingSpinner from '../../generalComponents/loadingSpinner/LoadingSpinner.js'
import { ACCESS_TOKEN/*, MONTHS */ } from '../../../constants'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import SuccessMessage from '../../generalComponents/Message/SuccessMessage'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import autoLogout from '../../../generalFunctions/autoSignout'
import Avatar from '@material-ui/core/Avatar'


const useStyles = makeStyles({
  card: {
    marginTop: '20px',
    width: '100%',
    maxWidth: 750,
    backgroundColor: 'rgba(255, 255, 255, 0.95)'
  },
  title: {
    color: '#3C810D',
    fontSize: '35px'
  },
  subtitle: {
    color: '#3C810D',
    fontSize: '20px'
  },
  subtitle2: {
    color: '#3C810D',
    fontSize: '20px',
    marginTop: 'auto',
    marginBottom: '5px'
  },
  container: {
    display: 'flex',
    justifyContent: 'center'
  },
  error: {
    color: '#F0072D'
  },
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
    height: 'auto',
    width: '100%'
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto'
  },
  textLong: {
    wordBreak: 'break-word',
    maxWidth: '100%'
  },
  textField: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '20px',
    marginBottom: 'auto',
    height: 'auto',
    width: '100%'
  },
  avatar: {
    width: 100,
    height: 100
  },
  margin: {
    marginTop: '15px'
  }
})

export default function ScreenVerFichaAnimal (props) {
  const idFicha = props.match.params.idFicha
  const classes = useStyles()
  const { promiseInProgress } = usePromiseTracker()
  const apiURL = process.env.REACT_APP_API_URL
  const [ficha, setFicha] = React.useState()
  const [fotos, setFotos] = React.useState([])
  const [fotoPerfil, setFotoPerfil] = React.useState([])
  const [reportes, setReportes] = React.useState([])

  React.useEffect((event) => {
    var config = { headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) } }
    const url = `${apiURL}/fichas_animal/${idFicha}`
    trackPromise(
      axios.get(url, config).then((response) => {
        setFicha(response.data)
      }).catch(_error => {
        if (_error.response.status === 401) {
          autoLogout()
        }
        console.log('Error al cargar voluntarios: \n', _error) // TODO show errors to the user
      }))
  }, [])

  React.useEffect((event) => {
    var config = { headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) } }
    const url = `${apiURL}/fichas_animal/${idFicha}/reportes`
    trackPromise(
      axios.get(url, config).then((response) => {
        setReportes(response.data.map((obj) => {
          return obj.idReporte
        }))
      }).catch(responseError => {
        if (responseError.response.status === 401) {
          autoLogout()
        }
        console.log(responseError) // TODO show errors to the user
      }))
  }, [])

  function convertText (texto) {
    return (texto) ? texto.replace(/_/g, ' ') : null
  }

  function convertDate (fecha) {
    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    const currentDate = iOS ? new Date(fecha.substr(0, 19)) : new Date(fecha)
    const formattedDate = (currentDate.getDate()).toString() + '/' + (currentDate.getMonth() + 1).toString() + '/' + currentDate.getFullYear().toString()
    return formattedDate
  }

  function getHour (fecha) {
    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    const date = iOS ? new Date(fecha.substr(0, 19)) : new Date(fecha)
    const hours = date.getHours()
    const minutes = '0' + date.getMinutes()
    const formattedTime = hours + ':' + minutes.substr(-2)
    return formattedTime
  }

  function convertBool (boolvalue) {
    if (boolvalue === true) return 'Si'
    else return 'No'
  }

  React.useEffect((event) => {
    if (ficha) {
      cargarFotos()
    }
  }, [ficha])

  React.useEffect((event) => {
    if (ficha) {
      cargarFotosPerfil()
    }
  }, [ficha])

  async function cargarFotos () {
    const headers = {
      'Content-Type': 'text/plain',
      Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN)
    }
    var promises = []
    var fotosId = []
    for (const link of ficha.fotosFichaAnimal) {
      var url = `${apiURL}${link}`
      promises.push(axios.get(url, { headers }))
    }
    try {
      var responses = await Promise.all(promises)
      fotosId = (responses.map((foto) => { return foto.data }))
      setFotos(fotosId)
    } catch (err) {
      console.log('Error al traer fotos: \n', err)
    }
  }

  async function cargarFotosPerfil () {
    const headers = {
      'Content-Type': 'text/plain',
      Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN)
    }
    var promises = []
    var fotosId = []
    for (const link of ficha.fotoIdentificacion) {
      var url = `${apiURL}${link}`
      promises.push(axios.get(url, { headers }))
    }
    try {
      var responses = await Promise.all(promises)
      fotosId = (responses.map((foto) => { return foto.data }))
      setFotoPerfil(fotosId)
    } catch (err) {
      console.log('Error al traer fotos: \n', err)
    }
  }

  const volver = function () {
    window.history.go(-1)
    window.scrollTo(0, 0)
  }
  const [message, setMessage] = React.useState([])
  const [openSuccess, setOpenSuccess] = React.useState(false)

  React.useEffect((event) => {
    if (props.location.state && props.location.state.msj) {
      setOpenSuccess(true)
      setMessage(props.location.state.msj)
    }
  }, [])

  function handleCloseMsj () {
    setOpenSuccess(false)
  }

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        <SuccessMessage open={openSuccess} handleOpen={() => { setOpenSuccess(!openSuccess) }} message={message} handleCloseMsj={handleCloseMsj} />
        {promiseInProgress
          ? (
            <CardContent>
              <LoadingSpinner />
            </CardContent>
          )
          : (ficha)
            ? (
              <CardContent>
                <Typography align='center' className={classes.title} variant='h4'>
                  Ficha ingreso
                </Typography>
                {fotoPerfil[0]
                  ? (
                    <Table className={classes.root}>
                      <TableBody>
                        <TableRow>
                          <TableCell align='left'>
                            <Typography variant='h5'>
                              Ficha #{idFicha}
                            </Typography>
                            <Typography className={classes.margin} variant='subtitle2'>
                              Ingreso: {convertDate(ficha.fechaHora)}
                            </Typography>
                            <Typography className={classes.margin} variant='subtitle2'>
                              Hora: {getHour(ficha.fechaHora)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Avatar
                              alt=''
                              src={`data:image/jpeg;base64,${fotoPerfil}`}
                              className={classes.avatar}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  )
                  : (
                    <Table className={classes.root}>
                      <TableBody>
                        <TableRow>
                          <TableCell align='left'>
                            <Typography variant='subtitle2'>
                              Ficha #{idFicha}
                            </Typography>
                          </TableCell>
                          <TableCell align='center'>
                            <Typography variant='subtitle2'>
                              Ingreso: {convertDate(ficha.fechaHora)}
                            </Typography>
                          </TableCell>
                          <TableCell align='right'>
                            <Typography variant='subtitle2'>
                              Hora: {getHour(ficha.fechaHora)}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>)}
                {(ficha.departamento || ficha.ubicacion) &&
                  <SocobiomaExpansion
                    resumen='Procedencia'
                    titulo1='Departamento'
                    valor1={ficha.departamento}
                    titulo2='Ubicación'
                    valor2={ficha.ubicacion}
                  />}
                {(ficha.nombreReportero || ficha.telefonoReportero || ficha.correoReportero) &&
                  <SocobiomaExpansion
                    resumen='Persona que realizó el llamado'
                    titulo1='Nombre'
                    valor1={ficha.nombreReportero}
                    titulo2='Teléfono'
                    valor2={ficha.telefonoReportero}
                    titulo3='Correo'
                    valor3={ficha.correoReportero}
                  />}
                {ficha.nombreCompletoReceptor &&
                  <SocobiomaExpansion
                    resumen='Persona que recibe el animal'
                    titulo1='Nombre'
                    valor1={ficha.nombreCompletoReceptor}
                    titulo2='Usuario'
                    valor2={ficha.receptorAnimalUsuario}
                  />}
                <Card className={classes.card}>
                  <h3 align='center' className={classes.subtitle}>Información del Animal</h3>
                  <CardContent>
                    <Table className={classes.root}>
                      <TableBody>
                        <TableRow>
                          <TableCell align='left' className={classes.textLong}>
                            {ficha.ubicacionActual &&
                              <Typography variant='subtitle2'>
                                Ubicación actual: {ficha.ubicacionActual}
                              </Typography>}
                          </TableCell>
                          <TableCell align='right'>
                            {ficha.fechaIngresoSocobiomaMald &&
                              <Typography align='right' variant='subtitle2'>
                                Fecha de ingreso a Socobioma: {convertDate(ficha.fechaIngresoSocobiomaMald)}
                              </Typography>}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <SocobiomaExpansion
                      resumen='Animal'
                      titulo1='Clase'
                      valor1={ficha.clase}
                      titulo2='Nombre común'
                      valor2={ficha.nombreComunFichaAnimal}
                      titulo3='Especie'
                      valor3={ficha.especie}
                    />
                    <Card className={classes.card}>
                      <Table>
                        <TableBody>
                          {ficha.estado &&
                            <TableRow>
                              <TableCell align='left'>Estado: </TableCell>
                              <TableCell align='left'>{convertText(ficha.estado)}</TableCell>
                            </TableRow>}
                          {ficha.fechaMuerte &&
                            <TableRow>
                              <TableCell align='left'>Fecha de muerte: </TableCell>
                              <TableCell align='left'>{convertDate(ficha.fechaMuerte)}</TableCell>
                            </TableRow>}
                          {ficha.capturadoVarado &&
                            <TableRow>
                              <TableCell align='left'>Varamiento/Captura: </TableCell>
                              <TableCell align='left'>{ficha.capturadoVarado}</TableCell>
                            </TableRow>}
                          {ficha.motivoIngreso &&
                            <TableRow>
                              <TableCell align='left'>Motivo de Ingreso: </TableCell>
                              <TableCell align='left'>{convertText(ficha.motivoIngreso)}</TableCell>
                            </TableRow>}
                          {ficha.sexo &&
                            <TableRow>
                              <TableCell align='left'>Sexo: </TableCell>
                              <TableCell align='left'>{ficha.sexo}</TableCell>
                            </TableRow>}
                          {ficha.estadio &&
                            <TableRow>
                              <TableCell align='left'>Estadío: </TableCell>
                              <TableCell align='left'>{convertText(ficha.estadio)}</TableCell>
                            </TableRow>}
                        </TableBody>
                      </Table>
                    </Card>
                    {(ficha.probableCausaMuerte || ficha.probableCausaMuerte2) &&
                      <SocobiomaExpansion
                        resumen='Probable causa de muerte'
                        titulo1='Accidental/Activa'
                        valor1={ficha.probableCausaMuerte}
                        titulo2='Causa'
                        valor2={convertText(ficha.probableCausaMuerte2)}
                      />}
                    {(ficha.numeroMarca || ficha.tipoMarca || ficha.leyendaMarca) &&
                      <SocobiomaExpansion
                        resumen='Marcas'
                        titulo1='Número de marca'
                        valor1={ficha.numeroMarca}
                        titulo2='Tipo'
                        valor2={ficha.tipoMarca}
                        titulo3='Leyenda'
                        valor3={ficha.leyendaMarca}
                      />}
                    <Card className={classes.card}>
                      <Table>
                        <TableBody>
                          {ficha.pesoIngreso &&
                            <TableRow>
                              <TableCell align='left'>Peso al ingreso: </TableCell>
                              <TableCell align='left'>{ficha.pesoIngreso} kg</TableCell>
                            </TableRow>}
                          {ficha.necropsia != null &&
                            <TableRow>
                              <TableCell align='left'>Necropsia: </TableCell>
                              <TableCell align='left'>{convertBool(ficha.necropsia)}</TableCell>
                            </TableRow>}
                          {ficha.tumores != null &&
                            <TableRow>
                              <TableCell align='left'>Tumores: </TableCell>
                              <TableCell align='left'>{convertBool(ficha.tumores)}</TableCell>
                            </TableRow>}
                          {ficha.placas != null &&
                            <TableRow>
                              <TableCell align='left'>Placas: </TableCell>
                              <TableCell align='left'>{convertBool(ficha.placas)}</TableCell>
                            </TableRow>}
                          {ficha.rehabilitacion != null &&
                            <TableRow>
                              <TableCell align='left'>Rehabilitación: </TableCell>
                              <TableCell align='left'>{convertBool(ficha.rehabilitacion)}</TableCell>
                            </TableRow>}
                        </TableBody>
                      </Table>
                    </Card>
                    {(ficha.fechaEgreso || ficha.pesoEgreso) &&
                      <SocobiomaExpansion
                        resumen='Egreso'
                        titulo1='Fecha de alta'
                        valor1={convertDate(ficha.fechaEgreso)}
                        titulo2='Peso al egreso'
                        valor2={ficha.pesoEgreso + ' kg'}
                      />}
                    {(ficha.muestrasSangre || ficha.muestrasBiopsiaLesiones || ficha.muestraAdn != null || ficha.muestraAdnMuscOPiel) &&
                      <SocobiomaExpansion
                        resumen='Muestras'
                        titulo1='Muestras de ADN'
                        valor1={convertBool(ficha.muestraAdn)}
                        titulo2='Músculo o Piel'
                        valor2={ficha.muestraAdnMuscOPiel}
                        titulo3='Sangre'
                        valor3={ficha.muestrasSangre}
                        titulo4='Biopsia Lesiones'
                        valor4={ficha.muestrasBiopsiaLesiones}
                      />}
                    {ficha.fotosFichaAnimal[0] &&
                      <Card className={classes.card}>
                        <CardContent>
                          <h5 align='left' className={classes.subtitle}>Fotos</h5>
                          <SocobiomaGridList
                            items={fotos}
                            noDeleteOption
                          />
                        </CardContent>
                      </Card>}
                    {(ficha.enterradoOLiberado || ficha.lugarDestinoFinal || ficha.fechaDestinoFinal) &&
                      <SocobiomaExpansion
                        resumen='Destino Final'
                        titulo1='Liberado/Enterrado'
                        valor1={ficha.enterradoOLiberado}
                        titulo2='Fecha'
                        valor2={convertDate(ficha.fechaDestinoFinal)}
                        titulo3='Lugar'
                        valor3={ficha.lugarDestinoFinal}
                      />}
                    {ficha.observacionesGenerales &&
                      <Card className={classes.card}>
                        <CardContent>
                          <h5 align='left' className={classes.subtitle2}>Observaciones Generales</h5>
                          {ficha.observacionesGenerales}
                        </CardContent>
                      </Card>}
                    {(ficha.ojos || ficha.sensorio || ficha.boca || ficha.estadoCorporal || ficha.cloacaAno || ficha.piel) &&
                      <SocobiomaExpansion
                        resumen='Observaciones Semiológicas'
                        titulo1='Ojos'
                        valor1={ficha.ojos}
                        titulo2='Sensorio'
                        valor2={ficha.sensorio}
                        titulo3='Boca'
                        valor3={convertText(ficha.boca)}
                        titulo4='Estado corporal'
                        valor4={convertText(ficha.estadoCorporal)}
                        titulo5='Cloaca Ano'
                        valor5={convertText(ficha.cloacaAno)}
                        titulo6='Piel'
                        valor6={ficha.piel}
                      />}
                    {ficha.procedimientoInmediato &&
                      <Card className={classes.card}>
                        <CardContent>
                          <h5 align='left' className={classes.subtitle2}>Procedimiento Inmediato</h5>
                          {ficha.procedimientoInmediato}
                        </CardContent>
                      </Card>}
                    {ficha.limpiezaAlIngreso &&
                      <Card className={classes.card}>
                        <CardContent>
                          <h5 align='left' className={classes.subtitle2}>Limpieza del animal al ingreso</h5>
                          {ficha.limpiezaAlIngreso}
                        </CardContent>
                      </Card>}
                    {reportes.length > 0 &&
                      <Card className={classes.card}>
                        <CardContent>
                          <h5 align='left' className={classes.subtitle2}>Reportes asociados</h5>
                        </CardContent>
                        <Grid
                          container
                          spacing={0}
                          alignItems='left'
                          justify='left'
                        >
                          {reportes.map(item => (
                            <TableCell align='left' key={item}>
                              <Link to={'/reporte/' + item}>Reporte #{item}</Link>
                            </TableCell>
                          ))}
                        </Grid>
                      </Card>}
                    <Link to={{
                      pathname: '/modificar_ficha',
                      state: {
                        idFicha: idFicha
                      }
                    }}
                    >
                      <Link to={{
                        pathname: '/historicos/' + idFicha,
                        state: {
                          baseFicha: ficha
                        }
                      }}
                      >
                        <SocobiomaButton
                          label='Ver historico'
                        />
                      </Link>

                      <SocobiomaButton
                        label='Modificar'
                      />

                    </Link>
                    <SocobiomaButton
                      label='Volver' onClick={volver}
                    />
                  </CardContent>
                </Card>
              </CardContent>
            )
            : (
              <CardContent>
                <Typography align='center' className={classes.error} variant='h5'>
                  La ficha que usted solicita no se encuentra en el sistema.
                </Typography>
                <SocobiomaButton
                  label='Volver' onClick={volver}
                />
              </CardContent>
            )}
      </Card>
    </div>
  )
}
