import React from 'react'
import axios from 'axios'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import MuiPhoneNumber from 'material-ui-phone-number'
import FormLabel from '@material-ui/core/FormLabel'
import { SocobiomaTextField, SocobiomaDateTimePicker, SocobiomaButton, SocobiomaSelect, SocobiomaDatePicker, SocobiomaInputFotos, SocobiomaGridList } from '../../socobioma_components'
import '../../../constants/card.css'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { usePromiseTracker, trackPromise } from 'react-promise-tracker'
import LoadingSpinner from '../../generalComponents/loadingSpinner/LoadingSpinner.js'
import { ACCESS_TOKEN } from '../../../constants'
import { Redirect } from 'react-router-dom'
import ErrorMsg from '../../generalComponents/Message/ErrorMessage'
import autoLogout from '../../../generalFunctions/autoSignout'


const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
    height: 'auto',
    width: '100%'
  },
  title: {
    color: '#3C810D'
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto'
  }
}))

export default function ScreenFichaAnimal(props) {
  const [redirect, setRedirect] = React.useState(false)
  const [expanded, setExpanded] = React.useState(false)
  const classes = useStyles()
  const [departamentos, setDepartamentos] = React.useState([null])
  const [voluntarios, setVoluntarios] = React.useState([])
  const [error, setError] = React.useState(false)
  const [openError, setOpenError] = React.useState(false)
  const [message, setMessage] = React.useState([])
  const [open, setOpen] = React.useState(false)
  const [clasesAnimal, setClasesAnimal] = React.useState([])
  const [especies, setEspecies] = React.useState([])
  const [voluntarioId, setVoluntarioId] = React.useState({})
  const [voluntarioActual, setVoluntarioActual] = React.useState(null)
  const apiURL = process.env.REACT_APP_API_URL

  const estadosAnimal = ['En rehabilitación', 'Liberado', 'Muerto']
  const motivosIngreso = ['Red de pesca', 'Hidrocarburos', 'Golpes', 'Caza', 'Atropellado']
  const capturadoVarado = ['Varamiento', 'Captura']
  const probableCausaMuerte = ['Accidental', 'Activa']
  const sexo = ['Macho', 'Hembra', 'Indefinido']
  const probableCausaMuerte2 = ['Red de pesca', 'Plásticos', 'Pesca', 'Hidrocarburos', 'Golpes', 'Asfixia', 'Ahogamiento', 'Caza', 'Atropellado']
  const estadio = ['Adulto', 'Subadulto', 'Juvenil', 'Cria', 'No sabe']
  const booleano = ['Si', 'No']
  const muestraAdnMuscOPiel = ['Musculo', 'Piel']
  const enterrOliber = ['Enterrado', 'Liberado']
  const semioOjos = ['Normales', 'Hundidos', 'Heridos', 'Ausencia']
  const semiSensorio = ['Alerta', 'Deprimido', 'Coma']
  const semiBoca = ['Normal', 'Con cuerpo extraño', 'Presencia de sangre', 'Lesiones']
  const semiEstadoCorporal = ['Bueno', 'Delgado', 'Muy delgado', 'Caquéxico']
  const semiCloaca = ['Materia fecal', 'Con cuerpo extraño', 'Presencia de sangre']
  const semiPiel = ['Normal', 'Tumores', 'Lesiones']
  const [dataRedirect, setdataRedirect] = React.useState({ path: '/', msj: null, push: true })

  const [ficha, setFicha] = React.useState({
    fecha: (new Date()).getTime(),
    fechaHora: (new Date()).getTime(),
    departamento: null,
    nombreReportero: null,
    telefonoReportero: '',
    correoReportero: null,
    receptorAnimal: null,
    nombreReceptor: null,
    ubicacion: null,
    ubicacionActual: null,
    fechaIngresoSocobiomaMald: null,
    nombreClase: null,
    nombreComunFichaAnimal: null,
    especie: null,
    estado: null,
    fechaMuerte: null,
    capturadoVarado: null,
    motivoIngreso: null,
    probableCausaMuerte: null,
    probableCausaMuerte2: null,
    sexo: null,
    estadio: null,
    numeroMarca: null,
    tipoMarca: null,
    leyendaMarca: null,
    pesoIngreso: null,
    necropsia: null,
    tumores: null,
    placas: null,
    rehabilitacion: null,
    pesoEgreso: null,
    fechaEgreso: null,
    muestraAdn: null,
    muestraAdnMuscOPiel: null,
    fotosFichaAnimal: [],
    fotoIdentificacion: [],
    enterradoOLiberado: null,
    fechaDestinoFinal: null,
    lugarDestinoFinal: null,
    observacionesGenerales: '',
    ojos: null,
    piel: null,
    sensorio: null,
    cloacaAno: null,
    boca: null,
    estadoCorporal: null,
    procedimientoInmediato: null,
    limpiezaAlIngreso: null,
    muestrasSangre: null,
    muestrasBiopsiaLesiones: null,
    idReporte: null
  })

  const handleChange = attribute => event => {
    const val = (!event.target.value) ? null : event.target.value
    setFicha({ ...ficha, [attribute]: val })
  }

  function handleChangePhone(value) {
    const val = (!value) ? null : value
    setFicha({ ...ficha, telefonoReportero: val })
  }

  const handleChangeBool = attribute => event => {
    setFicha({ ...ficha, [attribute]: event.target.value === null ? null : event.target.value === 'Si' })
  }

  const handleChangeFixes = attribute => event => {
    if (event.target.value === null) {
      setFicha({ ...ficha, [attribute]: null })
    } else {
      setFicha({ ...ficha, [attribute]: event.target.value.replace(/ /g, '_') })
    }
  }

  const handleChangeDate = attribute => date => {
    setFicha({ ...ficha, [attribute]: date.getTime() })
  }

  function handleChangeFotos(e) {
    var reader = new window.FileReader()
    reader.readAsBinaryString(e.target.files[0])
    reader.onload = function () {
      setFicha({ ...ficha, fotosFichaAnimal: [...ficha.fotosFichaAnimal, window.btoa(reader.result)] })
    }
  }

  function FotoPerfil(e) {
    var reader = new window.FileReader()
    reader.readAsBinaryString(e.target.files[0])
    reader.onload = function () {
      var foto = window.btoa(reader.result)
      var index = ficha.fotosFichaAnimal.indexOf(foto)
      if (index === -1) {
        setFicha({ ...ficha, fotoIdentificacion: [foto] })
      } else {
        setMessage('Esta foto ya existe')
        setOpenError(true)
      }
    }
  }

  function RemoveFotoPerfil(e) {
    var array = [...ficha.fotoIdentificacion]
    array.splice(0, 1)
    setFicha({ ...ficha, fotoIdentificacion: array })
  }

  function handleChangeReceptor(value) {
    const name = value.target.value
    if (name === 'Otro') {
      setFicha({ ...ficha, receptorAnimal: null })
    } else {
      setFicha({ ...ficha, receptorAnimal: voluntarioId[name], nombreReceptor: null })
    }
    setVoluntarioActual(name)
  }

  function handleChangeRemoveFoto(img) {
    var array = [...ficha.fotosFichaAnimal]
    var index = array.indexOf(img)
    if (index !== -1) {
      array.splice(index, 1)
      setFicha({ ...ficha, fotosFichaAnimal: array })
    } else {
      console.log('Not found loaded img')
    }
  }

  React.useEffect((event) => {
    const url = `${apiURL}/departamentos`
    trackPromise(
      axios.get(url).then((response) => {
        setDepartamentos(response.data.map((obj) => {
          return obj.nombre
        }))
      }).catch(responseError => {
        console.log(responseError)
      }))
  }, [])

  React.useEffect((event) => {
    var config = { headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) } }
    const url = `${apiURL}/usuarios`
    trackPromise(
      axios.get(url, config).then((response) => {
        const arr = response.data.map((obj) => {
          return obj.nombreUsuario
        })
        arr.push('Otro')
        setVoluntarios(arr)
        const aux = {}
        for (let i = 0; i < response.data.length; i++) {
          aux[response.data[i].nombreUsuario] = response.data[i].idUsuario
        }
        setVoluntarioId(aux)
      }).catch(_error => {
        console.log('Error al cargar voluntarios: \n', _error)
        if (_error.response.status === 401) {
          autoLogout()
        }
      }))
  }, [])

  React.useEffect((event) => {
    var config = { headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) } }
    const url = `${apiURL}/clases/fichas`
    trackPromise(
      axios.get(url, config).then((response) => {
        setClasesAnimal(response.data)
      }).catch(_error => {
        console.log('Error al ingresar el reporte: \n', _error)
        if (_error.response.status === 401) {
          autoLogout()
        }
      }))
  }, [])

  React.useEffect((event) => {
    var config = { headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) } }
    const url = `${apiURL}/especies`
    trackPromise(
      axios.get(url, config).then((response) => {
        setEspecies(response.data)
      }).catch(_error => {
        console.log('Error al ingresar el reporte: \n', _error)
      }))
  }, [])

  React.useEffect((event) => {
    if (props.location.state) {
      const baseReport = props.location.state.baseReport
      const fotosReṕort = props.location.state.fotosReport
      setFicha(
        {
          ...ficha,
          departamento: baseReport.departamento,
          nombreClase: baseReport.clase,
          nombreComunFichaAnimal: baseReport.nombreComunReporte,
          observacionesGenerales: baseReport.observaciones,
          ubicacion: baseReport.ubicacion,
          nombreReportero: baseReport.nombreContacto,
          correoReportero: baseReport.emailContacto,
          telefonoReportero: baseReport.telefonoContacto,
          idReporte: baseReport.idReporte,
          fotosFichaAnimal: fotosReṕort
        }
      )
    }
  }, [])

  function commonNamesFun() {
    return [...clasesAnimal[ficha.nombreClase]].sort()
  }

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleCloseSave = () => {
    setOpen(false)
  }

  const handleCloseDiscard = () => {
    setFicha({ ...ficha, ojos: null, sensorio: null, boca: null, estadoCorporal: null, cloacaAno: null, piel: null })
    setOpen(false)
  }

  function handleChangeADN(event) {
    const val = event.target.value === 'Si'
    setFicha({ ...ficha, muestraAdn: val, muestraAdnMuscOPiel: null })
  }

  const { promiseInProgress } = usePromiseTracker()

  const cancelar = function () {
    window.scrollTo(0, 0)
    setRedirect(true)
  }

  const postFicha = function (event) {
    var abrioError = false

    if (!ficha.departamento && !abrioError) {
      setMessage('Debe especificar el departamento de procedencia')
      setOpenError(true)
      abrioError = true
    }

    if (!ficha.ubicacion && !abrioError) {
      setMessage('Debe especificar la ubicación de procedencia')
      setOpenError(true)
      abrioError = true
    }

    if ((ficha.correoReportero !== null && !ficha.correoReportero.match('.+@.+')) && !abrioError) {
      setMessage('Correo con formato inválido')
      setOpenError(true)
      abrioError = true
    }

    if (!ficha.receptorAnimal && !ficha.nombreReceptor && !abrioError) {
      setMessage('Debe especificar quién recibió el animal')
      setOpenError(true)
      abrioError = true
    }

    if (!ficha.ubicacionActual && !abrioError) {
      setMessage('Debe especificar la ubicación actual')
      setOpenError(true)
      abrioError = true
    }

    if (!ficha.nombreClase && !abrioError) {
      setMessage('Debe especificar una clase')
      setOpenError(true)
      abrioError = true
    }

    if (!ficha.nombreComunFichaAnimal && !abrioError) {
      setMessage('Debe especificar un nombre común')
      setOpenError(true)
      abrioError = true
    }

    if (!ficha.estado && !abrioError) {
      setMessage('Debe especificar un estado')
      setOpenError(true)
      abrioError = true
    }

    if (!ficha.departamento || !ficha.ubicacion || (!ficha.receptorAnimal && !ficha.nombreReceptor) ||
      !ficha.ubicacionActual || !ficha.nombreClase || !ficha.nombreComunFichaAnimal || !ficha.estado ||
      (ficha.correoReportero !== null && !ficha.correoReportero.match('.+@.+'))) {
      setError(true)
      window.scrollTo(0, 0)
    } else {
      if (ficha.telefonoReportero.length < 12) {
        ficha.telefonoReportero = ''
      }
      var url = `${apiURL}/fichas_animal`
      var config = { headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) } }
      trackPromise(
        axios.post(url, ficha, config).then(response => {
          setdataRedirect({ path: '/fichas/' + response.data, msj: 'Ficha de ingreso creada con éxito' })
          setRedirect(true)
        }).catch(responseError => {
          console.log(responseError)
          if (responseError.response.status === 401) {
            autoLogout()
          }
          setError(true)
        })
      )
    }
  }

  function expandVol() {
    setExpanded(!expanded)
  }

  return (
    <div class='container'>
      <Card class='card'>
        <ErrorMsg open={openError} handleOpen={() => { setOpenError(!openError) }} message={message} />
        {redirect
          ? (
            <Redirect
              push={dataRedirect.push}
              to={{
                pathname: dataRedirect.path,
                state: { msj: dataRedirect.msj }
              }}
            />
          )
          : (promiseInProgress)
            ? (
              <CardContent>
                <LoadingSpinner />
              </CardContent>
            )
            : (
              <CardContent>
                <Typography align='center' className={classes.title} variant='h4'>
                  Ficha Ingreso
                </Typography>
                <form className={classes.container} noValidate autoComplete='off' />
                <List className={classes.root}>
                  <ListItem>
                    <SocobiomaDateTimePicker
                      onChange={handleChangeDate('fechaHora')}
                      maxDate={new Date()}
                      value={ficha.fechaHora}
                      label='Fecha y hora de ingreso'
                    />
                  </ListItem>
                  <ListItem>
                    <FormLabel component='legend'>Procedencia</FormLabel>
                  </ListItem>
                  <ListItem>
                    <SocobiomaSelect
                      label='Departamento*'
                      value={ficha.departamento}
                      onChange={handleChange('departamento')}
                      error={!ficha.departamento && error}
                      items={departamentos}
                    />
                  </ListItem>
                  <ListItem>
                    <SocobiomaTextField
                      label='Ubicación*'
                      placeholder='Ubicación'
                      defaultValue={ficha.ubicacion}
                      onBlur={handleChange('ubicacion')}
                      error={(!ficha.ubicacion) && error}
                      maxLength={200}
                    />
                  </ListItem>
                  <ListItem>
                    <ExpansionPanel className={classes.root}>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                      >
                        <Typography className={classes.heading}>Persona que realiza el llamado</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <List className={classes.root}>
                          <ListItem>
                            <SocobiomaTextField
                              label='Nombre'
                              defaultValue={ficha.nombreReportero}
                              placeholder='Ingrese su nombre'
                              onBlur={handleChange('nombreReportero')}
                              maxLength={45}
                            />
                          </ListItem>
                          <ListItem>
                            <MuiPhoneNumber
                              name='phone'
                              label='Teléfono'
                              data-cy='user-phone'
                              regions='south-america'
                              defaultCountry='uy'
                              countryCodeEditable={false}
                              autoFormat={false}
                              fullWidth='true'
                              value={ficha.telefonoReportero}
                              onChange={handleChangePhone}
                              className={classes.phoneNumber}
                              maxLength={45}
                            />
                          </ListItem>
                          <ListItem>
                            <SocobiomaTextField
                              label='Correo'
                              value={ficha.correoReportero}
                              error={(ficha.correoReportero !== null && !ficha.correoReportero.match('.+@.+')) && error}
                              placeholder='ejemplo@socobioma.com'
                              onChange={handleChange('correoReportero')}
                              maxLength={60}
                            />
                          </ListItem>
                        </List>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </ListItem>

                  <ListItem>
                    <ExpansionPanel className={classes.root} expanded={expanded || (ficha.receptorAnimal === null && error)} onChange={expandVol}>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                      >
                        <Typography className={classes.heading}>Persona que recibe al animal*</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <List className={classes.root}>
                          <ListItem>
                            <SocobiomaSelect
                              label='Persona que recibe el animal*'
                              value={voluntarioActual}
                              onChange={handleChangeReceptor}
                              error={(!ficha.receptorAnimal && !ficha.nombreReceptor) && error}
                              items={voluntarios}
                            />
                          </ListItem>
                          {voluntarioActual === 'Otro' &&
                            <ListItem>
                              <SocobiomaTextField
                                error={voluntarioActual === 'Otro' && !ficha.nombreReceptor && error}
                                label='Nombre del voluntario'
                                defaultValue={ficha.nombreReceptor}
                                placeholder=''
                                onBlur={handleChange('nombreReceptor')}
                                maxLength={80}
                              />
                            </ListItem>}
                        </List>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </ListItem>
                  <ListItem>
                    <FormLabel component='legend' style={{ marginTop: '30px' }}>Información del animal</FormLabel>
                  </ListItem>
                  <ListItem>
                    <SocobiomaTextField
                      label='Ubicación actual*'
                      placeholder='Ubicación actual'
                      defaultValue={ficha.ubicacionActual}
                      onBlur={handleChange('ubicacionActual')}
                      error={(!ficha.ubicacionActual) && error}
                      maxLength={200}
                    />
                  </ListItem>
                  <ListItem>
                    <SocobiomaDatePicker
                      onChange={handleChangeDate('fechaIngresoSocobiomaMald')}
                      maxDate={new Date()}
                      value={ficha.fechaIngresoSocobiomaMald}
                      label='Fecha de ingreso a Socobioma'
                    />
                  </ListItem>
                  <ListItem>
                    <SocobiomaSelect
                      label='Clase*'
                      value={ficha.nombreClase}
                      onChange={handleChange('nombreClase')}
                      error={!ficha.nombreClase && error}
                      items={Object.keys(clasesAnimal).sort()}
                    />
                  </ListItem>
                  <ListItem>
                    {ficha.nombreClase &&
                      <SocobiomaSelect
                        label='Nombre Común*'
                        value={ficha.nombreComunFichaAnimal}
                        onChange={handleChange('nombreComunFichaAnimal')}
                        error={!ficha.nombreComunFichaAnimal && error}
                        items={commonNamesFun()}
                      />}
                  </ListItem>
                  <ListItem>
                    <SocobiomaSelect
                      label='Especie'
                      value={ficha.especie}
                      onChange={handleChange('especie')}
                      items={[null].concat(especies.sort())}
                      itemsTags={['Especie'].concat(especies.sort())}
                    />
                  </ListItem>
                  <ListItem>
                    <SocobiomaSelect
                      label='Estado*'
                      value={ficha.estado && ficha.estado.replace(/_/g, ' ')}
                      onChange={handleChangeFixes('estado')}
                      error={!ficha.estado && error}
                      items={estadosAnimal}
                    />
                  </ListItem>
                  {ficha.estado === 'Muerto' &&
                    <div>
                      <ListItem>
                        <FormLabel component='legend' style={{ marginTop: '30px' }}>Posible causa de muerte</FormLabel>
                      </ListItem>
                      <ListItem>
                        <SocobiomaDatePicker
                          onChange={handleChangeDate('fechaMuerte')}
                          value={ficha.fechaMuerte}
                          maxDate={new Date()}
                          label='Fecha de muerte'
                        />
                      </ListItem>
                      <ListItem>
                        <SocobiomaSelect
                          label='Posible causa de muerte'
                          value={ficha.probableCausaMuerte}
                          onChange={handleChange('probableCausaMuerte')}
                          items={[null].concat(probableCausaMuerte)}
                          itemsTags={['Posible causa de muerte'].concat(probableCausaMuerte)}
                        />
                      </ListItem>
                      <ListItem>
                        <SocobiomaSelect
                          label='Posible causa de muerte'
                          value={ficha.probableCausaMuerte2 && ficha.probableCausaMuerte2.replace(/_/g, ' ')}
                          onChange={handleChangeFixes('probableCausaMuerte2')}
                          items={[null].concat(probableCausaMuerte2)}
                          itemsTags={['Posible causa de muerte'].concat(probableCausaMuerte2)}
                        />
                      </ListItem>
                    </div>}
                  <ListItem>
                    <SocobiomaSelect
                      label='Varamiento/Captura'
                      value={ficha.capturadoVarado}
                      onChange={handleChangeFixes('capturadoVarado')}
                      items={[null].concat(capturadoVarado)}
                      itemsTags={['Varamiento/Captura'].concat(capturadoVarado)}
                    />
                  </ListItem>
                  <ListItem>
                    <SocobiomaSelect
                      label='Motivo de ingreso'
                      value={ficha.motivoIngreso && ficha.motivoIngreso.replace(/_/g, ' ')}
                      onChange={handleChangeFixes('motivoIngreso')}
                      items={[null].concat(motivosIngreso)}
                      itemsTags={['Motivo de ingreso'].concat(motivosIngreso)}
                    />
                  </ListItem>
                  <ListItem>
                    <SocobiomaSelect
                      label='Sexo'
                      value={ficha.sexo}
                      onChange={handleChange('sexo')}
                      items={[null].concat(sexo)}
                      itemsTags={['Sexo'].concat(sexo)}
                    />
                  </ListItem>
                  <ListItem>
                    <SocobiomaSelect
                      label='Estadío'
                      value={ficha.estadio && ficha.estadio.replace(/_/g, ' ')}
                      onChange={handleChangeFixes('estadio')}
                      items={[null].concat(estadio)}
                      itemsTags={['Estadío'].concat(estadio)}
                    />
                  </ListItem>
                  <ListItem>
                    <ExpansionPanel className={classes.root}>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                      >
                        <Typography className={classes.heading}>Marcas</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <List className={classes.root}>
                          <ListItem>
                            <SocobiomaTextField
                              error={ficha.numeroMarca && !/^[0-9\b]+$/.test(ficha.numeroMarca)}
                              label='Número de marcas'
                              defaultValue={ficha.numeroMarca}
                              placeholder='1'
                              onBlur={handleChange('numeroMarca')}
                              maxLength={45}
                            />
                          </ListItem>
                          <ListItem>
                            <SocobiomaTextField
                              label='Tipo'
                              defaultValue={ficha.tipoMarca}
                              placeholder=''
                              onBlur={handleChange('tipoMarca')}
                              maxLength={45}
                            />
                          </ListItem>
                          <ListItem>
                            <SocobiomaTextField
                              label='Leyenda'
                              defaultValue={ficha.leyendaMarca}
                              placeholder='1'
                              onBlur={handleChange('leyendaMarca')}
                              maxLength={45}
                            />
                          </ListItem>
                        </List>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </ListItem>
                  <ListItem>
                    <SocobiomaTextField
                      error={ficha.pesoIngreso && !/^\d+(\.\d{0,2})?$/.test(ficha.pesoIngreso)}
                      label='Peso al ingreso (Kg)'
                      value={ficha.pesoIngreso}
                      placeholder='10.00'
                      onChange={handleChange('pesoIngreso')}
                      maxLength={10}
                    />
                  </ListItem>
                  <ListItem>
                    <SocobiomaSelect
                      label='Necropsia'
                      value={ficha.necropsia ? 'Si' : (ficha.necropsia !== null) ? 'No' : null}
                      onChange={handleChangeBool('necropsia')}
                      items={[null].concat(booleano)}
                      itemsTags={['Necropsia'].concat(booleano)}
                    />
                  </ListItem>
                  <ListItem>
                    <SocobiomaSelect
                      label='Tumores'
                      value={ficha.tumores ? 'Si' : (ficha.tumores !== null) ? 'No' : null}
                      onChange={handleChangeBool('tumores')}
                      items={[null].concat(booleano)}
                      itemsTags={['Tumores'].concat(booleano)}
                    />
                  </ListItem>
                  <ListItem>
                    <SocobiomaSelect
                      label='Placa'
                      value={ficha.placas ? 'Si' : (ficha.placas !== null) ? 'No' : null}
                      onChange={handleChangeBool('placas')}
                      items={[null].concat(booleano)}
                      itemsTags={['Placa'].concat(booleano)}
                    />
                  </ListItem>
                  <ListItem>
                    <SocobiomaSelect
                      label='Rehabilitación'
                      value={ficha.rehabilitacion ? 'Si' : (ficha.rehabilitacion !== null) ? 'No' : null}
                      onChange={handleChangeBool('rehabilitacion')}
                      items={[null].concat(booleano)}
                      itemsTags={['Rehabilitación'].concat(booleano)}
                    />
                  </ListItem>
                  <ListItem>
                    <SocobiomaDatePicker
                      onChange={handleChangeDate('fechaEgreso')}
                      value={ficha.fechaEgreso}
                      maxDate={new Date()}
                      label='Fecha de alta'
                    />
                  </ListItem>
                  <ListItem>
                    <SocobiomaTextField
                      error={ficha.pesoEgreso && !/^\d+(\.\d{0,2})?$/.test(ficha.pesoEgreso)}
                      label='Peso al egreso (Kg)'
                      value={ficha.pesoEgreso}
                      placeholder='10'
                      onChange={handleChange('pesoEgreso')}
                    />
                  </ListItem>
                  <ListItem>
                    <ExpansionPanel className={classes.root}>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                      >
                        <Typography className={classes.heading}>Muestras</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <List className={classes.root}>
                          <ListItem>
                            <SocobiomaSelect
                              label='Muesta ADN'
                              value={ficha.muestraAdn ? 'Si' : (ficha.muestraAdn !== null) ? 'No' : null}
                              onChange={handleChangeADN}
                              items={[null].concat(booleano)}
                              itemsTags={['Muesta ADN'].concat(booleano)}
                            />
                          </ListItem>
                          {ficha.muestraAdn &&
                            <ListItem>
                              <SocobiomaSelect
                                label='Muestra ADN'
                                value={ficha.muestraAdnMuscOPiel}
                                onChange={handleChange('muestraAdnMuscOPiel')}
                                items={[null].concat(muestraAdnMuscOPiel)}
                                itemsTags={['Muestra ADN'].concat(muestraAdnMuscOPiel)}
                              />
                            </ListItem>}
                          <ListItem>
                            <SocobiomaTextField
                              label='Sangre'
                              defaultValue={ficha.muestrasSangre}
                              placeholder=''
                              onBlur={handleChange('muestrasSangre')}
                              rows='3'
                            />
                          </ListItem>
                          <ListItem>
                            <SocobiomaTextField
                              label='Biopsia lesiones'
                              defaultValue={ficha.muestrasBiopsiaLesiones}
                              placeholder=''
                              onBlur={handleChange('muestrasBiopsiaLesiones')}
                              rows='3'
                            />
                          </ListItem>
                        </List>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </ListItem>
                  <ListItem>
                    <ExpansionPanel className={classes.root}>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                      >
                        <Typography className={classes.heading}>Fotos</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <List className={classes.root}>
                          <ListItem>
                            <SocobiomaInputFotos
                              onChange={handleChangeFotos}
                              id='1'
                            />
                            <SocobiomaGridList
                              items={ficha.fotosFichaAnimal}
                              onChange={handleChangeRemoveFoto}
                            />
                          </ListItem>

                        </List>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </ListItem>
                  <ListItem>
                    <ExpansionPanel className={classes.root}>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                      >
                        <Typography className={classes.heading}>Foto perfil</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <List className={classes.root}>
                          <ListItem>
                            <SocobiomaInputFotos
                              onChange={FotoPerfil}
                              id='2'
                            />
                            <SocobiomaGridList
                              items={ficha.fotoIdentificacion}
                              onChange={RemoveFotoPerfil}
                              col='1'
                            />
                          </ListItem>
                        </List>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </ListItem>
                  <ListItem>
                    <FormLabel component='legend' style={{ marginTop: '30px' }}>Destino final</FormLabel>
                  </ListItem>
                  <ListItem>
                    <SocobiomaSelect
                      label='Destino final'
                      value={ficha.enterradoOLiberado && ficha.enterradoOLiberado.replace(/_/g, ' ')}
                      onChange={handleChangeFixes('enterradoOLiberado')}
                      items={[null].concat(enterrOliber)}
                      itemsTags={['Destino final'].concat(enterrOliber)}
                    />
                  </ListItem>
                  <ListItem>
                    <SocobiomaDatePicker
                      onChange={handleChangeDate('fechaDestinoFinal')}
                      value={ficha.fechaDestinoFinal}
                      maxDate={new Date()}
                      label='Fecha del destino final'
                    />
                  </ListItem>
                  <ListItem>
                    <SocobiomaTextField
                      label='Lugar'
                      defaultValue={ficha.lugarDestinoFinal}
                      placeholder='Playa Brava'
                      onBlur={handleChange('lugarDestinoFinal')}
                      maxLength={300}
                    />
                  </ListItem>
                  <ListItem>
                    <SocobiomaTextField
                      label='Observaciones generales'
                      defaultValue={ficha.observacionesGenerales}
                      placeholder=''
                      onBlur={handleChange('observacionesGenerales')}
                      rows='3'
                    />
                  </ListItem>
                  <ListItem>
                    <SocobiomaButton onClick={handleClickOpen} label='Observaciones semiológicas' />
                    <Dialog onClose={handleCloseDiscard} aria-labelledby='semiologic-dialog-title' open={open}>
                      <DialogTitle id='semiologic-dialog-title' onClose={handleCloseDiscard}>
                        Observaciones semiológicas
                      </DialogTitle>
                      <DialogContent dividers>
                        <List>
                          <ListItem>
                            <SocobiomaSelect
                              label='Ojos'
                              value={ficha.ojos && ficha.ojos.replace(/_/g, ' ')}
                              onChange={handleChangeFixes('ojos')}
                              items={[null].concat(semioOjos)}
                              itemsTags={['Ojos'].concat(semioOjos)}
                            />
                          </ListItem>
                          <ListItem>
                            <SocobiomaSelect
                              label='Sensorio'
                              value={ficha.sensorio && ficha.sensorio.replace(/_/g, ' ')}
                              onChange={handleChangeFixes('sensorio')}
                              items={[null].concat(semiSensorio)}
                              itemsTags={['Sensorio'].concat(semiSensorio)}
                            />
                          </ListItem>
                          <ListItem>
                            <SocobiomaSelect
                              label='Boca'
                              value={ficha.boca && ficha.boca.replace(/_/g, ' ')}
                              onChange={handleChangeFixes('boca')}
                              items={[null].concat(semiBoca)}
                              itemsTags={['Boca'].concat(semiBoca)}
                            />
                          </ListItem>
                          <ListItem>
                            <SocobiomaSelect
                              label='Estado corporal'
                              value={ficha.estadoCorporal && ficha.estadoCorporal.replace(/_/g, ' ')}
                              onChange={handleChangeFixes('estadoCorporal')}
                              items={[null].concat(semiEstadoCorporal)}
                              itemsTags={['Estado corporal'].concat(semiEstadoCorporal)}
                            />
                          </ListItem>
                          <ListItem>
                            <SocobiomaSelect
                              label='Cloaca/ano'
                              value={ficha.cloacaAno && ficha.cloacaAno.replace(/_/g, ' ')}
                              onChange={handleChangeFixes('cloacaAno')}
                              items={[null].concat(semiCloaca)}
                              itemsTags={['Cloaca/ano'].concat(semiCloaca)}
                            />
                          </ListItem>
                          <ListItem>
                            <SocobiomaSelect
                              label='Piel'
                              value={ficha.piel && ficha.piel.replace(/_/g, ' ')}
                              onChange={handleChangeFixes('piel')}
                              items={[null].concat(semiPiel)}
                              itemsTags={['Piel'].concat(semiPiel)}
                            />
                          </ListItem>
                        </List>
                      </DialogContent>
                      <DialogActions>
                        <SocobiomaButton onClick={handleCloseDiscard} label='Borrar' />
                        <SocobiomaButton onClick={handleCloseSave} label='Guardar' />
                      </DialogActions>
                    </Dialog>
                  </ListItem>
                  <ListItem>
                    <SocobiomaTextField
                      label='Procedimiento inmediato'
                      defaultValue={ficha.procedimientoInmediato}
                      placeholder=''
                      onBlur={handleChange('procedimientoInmediato')}
                      rows='3'
                    />
                  </ListItem>
                  <ListItem>
                    <SocobiomaTextField
                      label='Limpieza al ingreso'
                      defaultValue={ficha.limpiezaAlIngreso}
                      placeholder=''
                      onBlur={handleChange('limpiezaAlIngreso')}
                      rows='3'
                    />
                  </ListItem>
                  <ListItem>
                    <SocobiomaButton
                      label='Cancelar' onClick={cancelar}
                    />
                    <SocobiomaButton
                      label='Crear' onClick={postFicha}
                    />
                  </ListItem>
                </List>
              </CardContent>
            )}
      </Card>
    </div>
  )
}
