/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React from 'react'
import { render } from "react-dom";
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import 'react-responsive-ui/style.css'
import MuiPhoneNumber from 'material-ui-phone-number'
import './style.css'
import ErrorMsg from './ErrorMsg'
import SuccessMessage from './SuccessMessage'
import { SocobiomaTextField,SocobiomaBigTextField, SocobiomaSelectProveedor, SocobiomaMonthDatePicker, SocobiomaSelect, SocobiomaGridList, SocobiomaInputFotos, SocobiomaButton } from '../../socobioma_components'
import { usePromiseTracker, trackPromise } from 'react-promise-tracker'
import LoadingSpinner from '../../generalComponents/loadingSpinner/LoadingSpinner.js'
import Typography from '@material-ui/core/Typography'
import './ScreenContactanos.css'
import facebookIcon from './logo-de-facebook.png'
import InstagramIcon from './ig.png'
import TwitterIcon from './twitter.png'
import WhatsAppIcon from './WA.png'
import CorreoIcon from './correo.png'
import IconButton from '@material-ui/core/IconButton'
import { Redirect } from 'react-router-dom'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import Table from '@material-ui/core/Table'
const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
    height: 'auto',
    width: '100%'
  },
  textField: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '20px',
    marginBottom: 'auto',
    height: 'auto',
    width: '100%'
  },
  radioButton: {
    margin: 'auto'
  },
  button: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '40px',
    marginBottom: 'auto',
    maxWidth: '842px',
    height: 'auto',
    width: '100%',
    backgroundColor: 'white'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  formControl: {
    backgroundColor: '#FCFAFA',
    margin: theme.spacing(1),
    width: '100%'
  },
   card: {
        maxWidth: '500px',
        height: '95%',
        width: '100%',
        display: 'inline-block',
        marginTop: '3%',
        margin: '5px 5px 5px 5px',
        backgroundColor: 'rgba(255, 255, 255, 0.93)',

    },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto'
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2)
  },
  title: {
    color: '#3C810D'
  },
  h6: {size: '1.132em',
    textAlign:'justify',
    fontFamily:'Source Sans Pro'},
}))

export default function ScreenContactanos (props) {
  const classes = useStyles()
  //const positionY = window.pageYOffset
 // const positionX = window.pageXOffset
  const [redirect, setRedirect] = React.useState(false)
  const [openError, setOpenError] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [openSuccess, setOpenSuccess] = React.useState(false)
  const [message, setMessage] = React.useState([])
  const apiURL = process.env.REACT_APP_API_URL
  const [dataRedirect, setdataRedirect] = React.useState({ path: '/', msj: null, push: true })
  const [correo, setCorreo] = React.useState({
    nombreRemitente: null,
    emailContacto: null,
    mensaje: ''
  })
  function limpiarFormulario () {
    setError(false)
    setOpenSuccess(false)
    setOpenError(false)
    setCorreo({
    ...correo,
    nombreRemitente: null,
    emailContacto: null,
    mensaje: ''
    })
  }
  const [notifyEmail, setNotifyEmail] = React.useState(false)

  const handleChange = attribute => event => {
    setCorreo({ ...correo, [attribute]: event.target.value })
  }
   
  const postReport = function (event) {
    var positionY = window.pageYOffset
    var positionX = window.pageXOffset
    if (correo.nombreRemitente === null ||
      (correo.emailContacto !== null && !correo.emailContacto.match('.+@.+'))) {
      setError(true)
      
      window.scrollTo(positionX,positionY)
      setOpenError(true)
      setMessage('Por favor complete los campos obligatorios y vuelva a enviar su reporte')
    } else {
      var url = `${apiURL}/contactanos`
      trackPromise(
        axios.post(url, correo).then(response => {
          limpiarFormulario()
          setMessage('Su mensaje ha sido enviado correctamente. ¡Gracias por contactarse!')
          setOpenSuccess(true)
          setOpenError(false)
//          setRedirect(true)
        }).catch(responseError => {
          console.log(responseError)
          console.log(`${apiURL}/contactanos`)
          setMessage('Hubo un error con la página, inténtelo más tarde')
          setOpenError(true)
        })
      )
    }
  }

  function handleCorreoToggle (event) {
    setNotifyEmail(!notifyEmail)
  }

  function handleChangeEmail (event) {
    setCorreo({ ...correo, emailContacto: event.target.value.trim() })
  }

  React.useEffect((event) => {
    if (!notifyEmail) {
      setCorreo({ ...correo, emailContacto: null })
    }
  }, [notifyEmail])


  const cancelar = function () {
    window.scrollTo(0, 0)
//    setRedirect(true)
  }

  const { promiseInProgress } = usePromiseTracker()

  return (
<div class="restOfScreenForContactanosScreen">
    <div className={classes.container}>
      <Card className={classes.card}>
        <ErrorMsg open={openError} onHandleOpen={() => { setOpenError(!openError) }} message={message} />
        <SuccessMessage open={openSuccess} handleOpen={() => { setOpenSuccess(!openSuccess) }} message={message} />
        {promiseInProgress
            ? (
              <CardContent>
                <LoadingSpinner />
              </CardContent>
            )
            : (
              <CardContent>
       <Table   className={classes.table}>
              <TableBody>
              <TableRow>
                <TableCell  align='center'>
                <Typography align='center' className={classes.title} variant='h4'>
                  ¡Contáctanos!
                </Typography>
                </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell  align='center'>
                        <Typography className={classes.h6} >
                           Deja un mensaje a continuación y/o síguenos a través de las redes, ¡Gracias!
                        </Typography>
                    </TableCell>
                </TableRow>
                <TableRow>
                <TableCell align='center'>
                <form className={classes.container} noValidate autoComplete='off'>
                  <List className={classes.root}>
                    <ListItem id='name'>
                      <SocobiomaTextField
                        label='Nombre'
                        maxLength={60}
                        value={correo.nombreRemitente}
                        onChange={handleChange('nombreRemitente')}
                      />
                    </ListItem>
                      <ListItem id='email'>
                        <SocobiomaTextField
                          maxLength={60}
                          error={!(correo.emailContacto !== null && correo.emailContacto.match('.+@.+')) && error}
                          label='Correo'
                          value={correo.emailContacto}
                          placeholder='ejemplo@socobioma.com'
                          onChange={handleChangeEmail}
                        />
                      </ListItem>  
                    <ListItem id='numeroTarjeta'>

                      <SocobiomaBigTextField
                        label='Mensaje'
                        maxLength={300}
                        rows='3'
                        value={correo.mensaje}
                        onChange={handleChange('mensaje')}
                      />
                    </ListItem>
                    <ListItem>
                      <SocobiomaButton
                        label='Cancelar' onClick={cancelar}
                      />
                      <SocobiomaButton
                        label='Enviar' onClick={postReport}
                      />
                    </ListItem>

                  </List>
                </form>
                 </TableCell>
                </TableRow>
                
                 <TableRow>
                    <TableCell  align='center'>
                <IconButton href='https://www.facebook.com/socobioma.org/'>
                <img src={facebookIcon} className='responsive' />
              </IconButton>
               <IconButton href='https://www.instagram.com/so.co.bio.ma/'>
                <img src={InstagramIcon} className='responsive' />
              </IconButton>
              <IconButton href='https://www.twitter.com/Socobioma1/'>
                <img src={TwitterIcon} className='responsive' />
              </IconButton>
              <Typography align='center' className={classes.title} variant='h5'>
                    <img src={CorreoIcon} className='responsive'  /> 
                     <span> </span> info@socobioma.org 
                </Typography>
              <Typography align='center' className={classes.title} variant='h5'>
                    <img src={WhatsAppIcon} className='responsive'  /> 
                     <span> </span> +598 92 727 317
                </Typography>
                </TableCell>
                </TableRow>

                </TableBody>
                </Table>
              </CardContent>
            )}
      </Card>
    </div>
    </div>
  )
}

