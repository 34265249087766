// TODO contendor pagina Sumate.
// trae informacion del backend y llama a los componentes de presentacion pasandoles los datos pertinentes
import React from 'react'
import { render } from "react-dom";

import SuccessMessage from '../../generalComponents/Message/SuccessMessage'

import {  animateScroll as scroll } from "react-scroll";
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Pagination from 'material-ui-flat-pagination'
import Grid from '@material-ui/core/Grid'
import { ACCESS_TOKEN } from '../../../constants'
import { usePromiseTracker, trackPromise } from 'react-promise-tracker'
import LoadingSpinner from '../../generalComponents/loadingSpinner/LoadingSpinner.js'
import { ANIMAL_FILE_STATE } from '../../../constants/index'
import ErrorMsg from '../../generalComponents/Message/ErrorMessage'
import LinesEllipsis from 'react-lines-ellipsis'
import { Link } from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar'
import autoLogout from '../../../generalFunctions/autoSignout'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import MuiPhoneNumber from 'material-ui-phone-number'
import FormLabel from '@material-ui/core/FormLabel'
import { SocobiomaTextField, SocobiomaDateTimePicker, SocobiomaButton, SocobiomaSelect, SocobiomaDatePicker, SocobiomaInputFotos, SocobiomaGridList } from '../../socobioma_components'
import '../../../constants/card.css'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import Carousel from "../../generalComponents/Carousel/Carousel";
import "react-responsive-carousel/lib/styles/carousel.css";
import { Container, Row, Col } from "reactstrap";

const useStyles = makeStyles({
    
    card: {
        maxWidth: '900px',
        height: '98%',
        display: 'inline-block',
        marginTop: '3%',
        margin: '5px 5px 5px 5px',
        backgroundColor: 'rgba(255, 255, 255, 0.93)',

    },
    cardTitleSpinner: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 'auto',
        marginBottom: 'auto',
        width: '100%',
        maxWidth: 1448,
        backgroundColor: 'rgba(255, 255, 255, 0.8)'
    },
    table: {
        padding: '9px 3px 9px 3px',
        margin: '3px',
        height: '40%'

    },
    text: {
        wordBreak: 'break-word',
        maxWidth: '100px'
    },
    textLong: {
        wordBreak: 'break-word',
        maxWidth: '100px'
    },
    fecha: {
        whiteSpace: 'nowrap'
    },
    hover: {
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, .8)'
        }
    },
    filter: {
        backgroundColor: 'rgba(255, 255, 255, 1)',
        position: 'fixed',
        top: '10',
        right: '0',
        '&:active': {
            backgroundColor: 'rgba(255, 255, 255, 1)'
        },
        '&:focus': {
            backgroundColor: 'rgba(255, 255, 255, 1)'
        },
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 1)',
            borderColor: '#3EA91D'
        }
    },
    checkboxIcons: {
        color: '#20DD03'
    },
    checkboxIconsRed: {
        color: '#F53232'
    },
    checkboxText: {
        paddingLeft: '15px',
        color: '#757575'
    },
    checkboxContainer: {
        display: 'flex',
        alignContent: 'space-between'
    },
    root: {
        maxWidth: '261px',
        width: '100%',
        padding: '0px'
    },
    grid: {
        height: '100%',

    },
    title: {
        color: '#3C810D'
        
    },
    titleCharla: {
        color: '#3C810D',
        textAlign:'left'
        
    },
    h6: {size: '1.132em',
    textAlign:'justify',
    fontFamily:'Source Sans Pro'
},
    bigAvatar: {
        maxWidth: '900px',
        height: '91%',
        display: 'inline-block',
        marginTop: '3%',
        margin: '5px 5px 5px 5px',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        width: 158,
        height: 158
    }
})

export default function ScreenEducacion() {
    const [message, setMessage] = React.useState([])
    const [openSuccess, setOpenSuccess] = React.useState(false)
    const classes = useStyles()


    function handleCloseMsj() {
        setOpenSuccess(false)
    }

    return (
        <div class="restOfScreenForEducacionScreen">
            <Container> 
                    <div class="col center">
                        <Card className={classes.card}>
                        <CardContent className={classes.grid}>
                            <Grid
                                container
                                spacing={0}
                                alignItems='center'
                                justify='center'
                                textOverflow='hidden'
                                className={classes.grid}
                                >
                                <Table   className={classes.table}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align='center'>
                                                <Typography className={classes.title} variant='h4'>
                                                    Educación
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell  align='center'>
                                                <Typography className={classes.h6}  >
                                                   Con cada animal que ingresa a ser rehabilitado, recibimos información muy valiosa que 
                                                   refleja lo que está ocurriendo con nuestro medio ambiente. Esto nos permite investigar 
                                                   sobre las causas y qué hacer para corregir el impacto humano directo o indirecto sobre 
                                                   estas especies. Muchas veces es por desconocimiento o temor que nuestras acciones ponen 
                                                   en peligro a otras especies. Es por ello que es de vital importancia transmitir esta 
                                                   información y generar así el cambio necesario para lograr una convivencia más armoniosa
                                                   entre todos los que habitamos el planeta. Es necesario conocer para proteger.
                                                    <Typography className={classes.h6} >  <br></br> </Typography>
                                                    </Typography>
                                            </TableCell>
                                        </TableRow>
                                        
                                         <Card className={classes.card}>
                        <CardContent className={classes.grid}>
                            <Grid
                                container
                                spacing={0}
                                alignItems='center'
                                justify='center'
                                textOverflow='hidden'
                                className={classes.grid}
                                >
                                        <TableRow>
                                            <TableCell  align='center'>
                                                <Typography className={classes.titleCharla} variant='subtitle1' >
                                                   Charla: ¿SOCO qué?
                                                   </Typography>
                                                    <Typography className={classes.h6} >  <br></br> </Typography>
                                                    <Typography className={classes.h6} > 
                                                    Temática: presentación de nuestra ONG, trayectoria, 
                                                    objetivos y anécdotas de rescatistas de animales 
                                                    silvestres en Uruguay.
                                                    </Typography>
                                            </TableCell>
                                        </TableRow>
                                        
                                        <TableRow>
                                            <TableCell  align='center'>
                                                <Typography className={classes.titleCharla} variant='subtitle1' >
                                                Charla: ¿Qué haces cuando me ves?  
                                                </Typography>                                                   
                                                <Typography className={classes.h6} >  <br></br> </Typography>
                                                <Typography className={classes.h6} > 
                                                Público objetivo: niños, jóvenes y adultos
                                                </Typography>
                                                <Typography className={classes.h6} >
                                                Temática: brindar información calificada respecto a 
                                                cómo actuar al momento de encontrarnos 
                                                con un animal silvestre.
                                                </Typography>                                                   
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell  align='center'>
                                                <Typography className={classes.titleCharla} variant='subtitle1' >
                                                Charla: De luces y sombras 
                                                </Typography>
                                                <Typography className={classes.h6} >  <br></br> </Typography>
                                                <Typography className={classes.h6} > 
                                                Público objetivo: niños y jóvenes 
                                                </Typography>
                                                <Typography className={classes.h6} >
                                                Temática: brindar los fundamentos para cuidar y plantar especies nativas. 
                                                Mostrar su importancia estética, medicinal y para la conservación de los ecosistemas. 
                                                Se finaliza la actividad plantando un árbol nativo.
                                                </Typography> 
                                            </TableCell>
                                        </TableRow>                                        
                                        <TableRow>
                                            <TableCell  >
                                                <Typography className={classes.titleCharla} variant='subtitle1' >
                                                Charla: Llegaron las ballenas 
                                                </Typography>
                                                <Typography className={classes.h6} >  <br></br> </Typography>
                                                <Typography className={classes.h6} > 
                                                Público objetivo: niños y jóvenes
                                                </Typography>
                                                <Typography className={classes.h6} >
                                                Temática: un acercamiento a la Ballena Franca Austral, su alimentación, reproducción, hábitos y peculiaridades. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;                      
                                                </Typography> 
                                            </TableCell>
                                        </TableRow>                                         
                                        <TableRow>
                                            <TableCell  align='center'>
                                                <Typography className={classes.titleCharla} variant='subtitle1' >
                                                Charla: Voluntario por un día con los pingüinos de Magallanes 
                                                </Typography>
                                                <Typography className={classes.h6} >  <br></br> </Typography>
                                                <Typography className={classes.h6} > 
                                                Público objetivo: jóvenes 
                                                </Typography>
                                                <Typography className={classes.h6} >
                                                Temática: información sobre la biología del Pingüino de Magallanes. 
                                                Procedimientos para su rescate y rehabilitación en nuestro Centro. 
                                                Ofrece una actividad práctica en las instalaciones de la ONG.
                                                </Typography> 
                                            </TableCell>
                                        </TableRow>                                         
                                        <TableRow>
                                            <TableCell  align='center'>
                                                <Typography className={classes.titleCharla} variant='subtitle1' >
                                                Charla: ¿Conoces a Micure?
                                                </Typography>
                                                <Typography className={classes.h6} >  <br></br> </Typography>
                                                <Typography className={classes.h6} > 
                                                Público objetivo: niños y jóvenes 
                                                </Typography>
                                                <Typography className={classes.h6} >
                                                Temática: a través del túnel de los sentidos nos acercamos al 
                                                conocimiento de las Comadrejas, su rol en el ecosistema 
                                                y la importancia de su conservación.
                                                </Typography> 
                                            </TableCell>
                                        </TableRow> 
                                        
                                        <TableRow>
                                            <TableCell  align='center'>
                                                <Typography className={classes.titleCharla} variant='subtitle1' >
                                                Charla: Pinnípedos del Uruguay, esos lobos no aúllan
                                                </Typography>
                                                <Typography className={classes.h6} >  <br></br> </Typography>
                                                <Typography className={classes.h6} > 
                                                Público objetivo: jóvenes 
                                                </Typography>
                                                <Typography className={classes.h6} >
                                                Temática: biología, costumbres y diferentes especies de Pinnípedos. 
                                                Qué hacer ante su presencia y cómo ayudarlos.
                                                </Typography> 
                                            </TableCell>
                                        </TableRow>
                                           </Grid>
                                    </CardContent>
                                    </Card>
                                 <TableRow>
                                        <TableCell  align='center'>
                                              <Typography className={classes.h6} >
                                              Por más información escríbenos a <b>info@socobioma.org</b>
                                              </Typography>
                                                <Typography className={classes.h6} >  <br></br> </Typography>
                                        </TableCell>                                            
                                        </TableRow>                                        
                                        <TableRow>
                                            <TableCell align='center'>
                                                <Avatar  alt='' src={'/images/educacion/educacion_1.jpg'} className={classes.bigAvatar} />
                                                <Avatar  alt='' src={'/images/educacion/educacion_2.jpg'} className={classes.bigAvatar} />
                                                <Avatar  alt='' src={'/images/educacion/educacion_3.jpg'} className={classes.bigAvatar} />
                                                <Avatar  alt='' src={'/images/educacion/educacion_4.jpg'} className={classes.bigAvatar} />
                                            </TableCell>
                                        </TableRow>                                       
                                    </TableBody>
                                </Table>
                            </Grid>
                        </CardContent>
                        </Card>
                    </div>
                   
          
                <SuccessMessage open={openSuccess} handleOpen={() => {
                    setOpenSuccess(!openSuccess)
                }} message={message} handleCloseMsj={handleCloseMsj} />
            </Container>
        </div>
            )


}