import React, { useState } from 'react'
import { render } from "react-dom";
import './ScreenInicio.css'
import SuccessMessage from '../../generalComponents/Message/SuccessMessage'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Carousel from "../../generalComponents/Carousel/Carousel";
import { BrowserRouter as Router, Route, Link as LinkForPage } from 'react-router-dom'

import { Link, animateScroll as scroll } from "react-scroll";


export default function ScreenInicio (props) {
  const [message, setMessage] = React.useState([])
  const [openSuccess, setOpenSuccess] = React.useState(false)
  const [openModal, setOpenModal] = useState(true);

  const onOpenModal = () => setOpenModal(true);
  const onCloseModal = () => setOpenModal(false);
  function handleCloseMsj () {
    setOpenSuccess(false)
  }
return(
    <div class=""> 
      
        <Carousel />
        <Modal open={openModal} onClose={onCloseModal} center>
            <nav>
                <Link to="/colaborar" onClick={onCloseModal}>
                     <img src='images/popup/ayuda_socobioma.jpeg' />
                </Link>
            </nav>

      </Modal>
        <SuccessMessage open={openSuccess} handleOpen={() => { setOpenSuccess(!openSuccess) }} message={message} handleCloseMsj={handleCloseMsj} />
     </div>
  )  
 
}
