import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";


export default () => (     
  <Carousel autoPlay interval={4500}  infiniteLoop  showThumbs={false} showArrows={true} showIndicators={true} showStatus={false} >
    <div  class="imgCarousel">
        <img src='images/carousel_images/1.jpg' />
    </div>
    <div  class="imgCarousel">
        <img src='images/carousel_images/2.jpg' />
    </div>
    <div  class="imgCarousel">
        <img src='images/carousel_images/3.jpg' />
    </div>
    <div  class="imgCarousel">
        <img src='images/carousel_images/4.jpg' />
    </div>
    <div  class="imgCarousel">
        <img src='images/carousel_images/5.jpg' />
    </div>
    <div  class="imgCarousel">
        <img src='images/carousel_images/6.jpg' />
    </div>
    <div  class="imgCarousel">
        <img src='images/carousel_images/7.jpg' />
    </div>
  </Carousel>
);

